
/* --------------------------------------------------------------
RESET
----------------------------------------------------------------- */

* { margin:0; padding:0; outline:none; border:0; list-style-type:none; text-decoration:none; list-style-image:none; box-sizing:border-box;}

html{font-size:100%; -webkit-text-size-adjust:100%; -ms-text-size-adjust:100%;}
header, section, footer, article, figcaption, hgroup, figure, details, aside, img, nav, time, bloquote, svg {display:block;}
audio, canvas, video {display:inline-block;*display:inline;*zoom:1;}
audio:not([controls]){display:none;}
[hidden]{display:none;}

object[type="application/gas-events-uni"] {width:0; height:0; position:absolute; overflow:hidden; display:block; text-indent:-999999px}

input[type="search"], input[type="submit"] {-webkit-appearance:textfield; -moz-box-sizing:content-box; -webkit-box-sizing:content-box; box-sizing:content-box; border-radius:0; -webkit-border-radius:0;}

/* --------------------------------------------------------------
GRID SYSTEM
----------------------------------------------------------------- */

.wrap {width:~"calc(100% - 16%)"; margin:0 8%; position:relative; clear:both;}
.dryWrap {width:96%; margin:0 28px; position:relative; clear:both;}


/* --------------------------------------------------------------
DEFAULT STYLES
----------------------------------------------------------------- */

html,body {height:100%;}

/* FORMATING CLASSES
---------------------------- */

.clear {clear:both;}
.hide {width:0; height:0; position:absolute; overflow:hidden; display:block; text-indent:-999999px}
hr {display:none;}

.left {float:left;}
.right {float:right;}
.center {margin:0 auto;}
.middle {display:block; position:relative; top:50%; transform:translateY(-50%);}
.middleOff {top:inherit; transform:none;}

.textRight {text-align:right;}
.textLeft {text-align:left;}
.textCenter {text-align:center;}

img.alignright {float:right; margin:0 0 1em 1em}
img.alignleft {float:left; margin:0 1em 1em 0}
img.aligncenter {display: block; margin-left: auto; margin-right: auto}

a img.alignright {float:right; margin:0 0 1em 1em}
a img.alignleft {float:left; margin:0 1em 1em 0}
a img.aligncenter {display: block; margin-left: auto; margin-right: auto}

.padding60 {padding-top:60px; padding-bottom:60px}
.padding40 {padding-top:40px; padding-bottom:40px}

span.bar {display:block; margin:0 auto; margin-top:15px; width:36px; height:1px; background:rgba(255,255,255,0.2);}

.cols(@col : 2, @gap : 40px){
  -moz-column-count: @col;
  -moz-column-gap: @gap;
  -webkit-column-count: @col;
  -webkit-column-gap: @gap;
  column-count: @col;
  column-gap: @gap;
}

.colsRules(@size : 1px, @style : solid, @cor : rgba(0,0,0,0.1)){
  -webkit-column-rule: @size @style @cor;
  -moz-column-rule: @size @style @cor;
  column-rule: @size @style @cor;
}

.rotate(@grau : 20.20deg){
  -moz-transform: rotate(@grau);
  -webkit-transform: rotate(@grau);
  -o-transform: rotate(@grau);
  -ms-transform: rotate(@grau);
  transform: rotate(@grau);
}

.skewX(@grau : -20.2deg){
  -moz-transform: rotate(0deg) skewX(@grau) skewY(0deg);
  -webkit-transform: rotate(0deg) skewX(@grau) skewY(0deg);
  -o-transform: rotate(0deg) skewX(@grau) skewY(0deg);
  -ms-transform: rotate(0deg) skewX(@grau) skewY(0deg);
  transform: rotate(0deg) skewX(@grau) skewY(0deg);
}
.skewY(@grau : -20.2deg){
  -moz-transform: rotate(0deg) skewX(0deg) skewY(@grau);
  -webkit-transform: rotate(0deg) skewX(0deg) skewY(@grau);
  -o-transform: rotate(0deg) skewX(0deg) skewY(@grau);
  -ms-transform: rotate(0deg) skewX(0deg) skewY(@grau);
  transform: rotate(0deg) skewX(0deg) skewY(@grau);
}

/* SIZES */

.full_X {width:100% !important; position:relative;}
.full_Y {height:100% !important; position:relative;}
.fullSize {width:100% !important; height:100% !important; position:relative;}

/* BACKGROUNDS */

.bgCover(@cor : rgba(25,25,25,0.9), @att : inherit) {
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  background-color:@cor;
  background-attachment: @att;
}

.bgContain(@cor : rgba(25,25,25,0.9), @att : inherit) {
  -webkit-background-size:contain;
  -moz-background-size:contain;
  -o-background-size:contain;
  background-size:contain;
  background-repeat:no-repeat;
  background-position:center center;
  background-color:@cor;
  background-attachment: @att;
}

.gradiente
{
  position:relative;
  width:100%;
  height:100%;
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 30%, rgba(0,0,0,1) 100%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 30%,rgba(0,0,0,1) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 30%,rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#4d000000',GradientType=0 );
}

.gradienteDark
{
  position:relative;
  width:100%;
  height:100%;
  background: -moz-linear-gradient(top, rgba(0,0,0,0.3) 30%, rgba(0,0,0,1) 100%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.3) 30%,rgba(0,0,0,1) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0.3) 30%,rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#4d000000',GradientType=0 );
}

.bgdark {overflow:hidden; background:rgba(0,0,0,0.4)}

.darkForm {background:rgba(0,0,0,0.2)}

/* COLORS */

@azul: #0d0d3f;
@azulEscovado: #15152e;
@azulClaro: #181850;
@azulEscuro: #0a0a20;
@rosa : #ed1785;
@rosaHover : #ff4da9;
@cinza : #b8b8b8;
@cinzaEscuro : #a7a7a7;
@cinzaDark : #343434;
@cinzapItem : #090909;
@branco : #fff;
@preto : #000;
@semiPreto : #090909;

/* FONTS */

@import 'clubedobatom.css';
@import "https://fonts.googleapis.com/css?family=Lato:300,400,600,900";

@font-face {
  font-family:'BebasNeue';
  src:  url('fontes/BebasNeue.eot?#iefix') format('embedded-opentype'),
        url('fontes/BebasNeue.otf')  format('opentype'),
        url('fontes/BebasNeue.woff') format('woff'),
        url('fontes/BebasNeue.ttf')  format('truetype'),
        url('fontes/BebasNeue.svg#BebasNeue') format('svg');
  font-weight: normal;
  font-style: normal;
}


/*
FIRA
LATO 300, Normal 400, Semibold 600, Black 900
*/

.bebas(@size : 13px, @weight : 400, @lineHeight : 18px, @cor : #fff, @style : normal, @upper : none){
  font-family:'BebasNeue',sans-serif;
  font-size:@size;
  font-weight:@weight;
  line-height:@lineHeight;
  color:@cor;
  font-style:@style;
  text-transform:@upper;
}

.lato(@size : 16px, @weight : 400, @lineHeight : 22px, @cor : @cinza, @style : normal, @upper : none){
  font-family:'Lato',sans-serif;
  font-size:@size;
  font-weight:@weight;
  line-height:@lineHeight;
  color:@cor;
  font-style:@style;
  text-transform:@upper;
}

.fontaws(@content : "\f000", @size : 18px, @cor : #000){
  font-family:FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content:@content;
  color:@cor;
  font-size:@size;
}

.fontclube(@content : "\e800", @size : 20px, @cor : #000){
  font-family:clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content:@content;
  color:@cor;
  font-size:@size;
}

/* EFFECTS */

.transition(@time : 400ms, @animation : ease){
  -webkit-transition:all @time @animation; /* Safari */
  transition:all @time @animation;
}

.placeholder(@cor : rgba(0,0,0,0.5), @style : normal){
  ::-webkit-input-placeholder {color:@cor; opacity:1; font-style:@style}
  ::-moz-placeholder {color:@cor; opacity:1; font-style:@style}
  :-ms-input-placeholder {color:@cor; opacity:1; font-style:@style}
  :-moz-placeholder {color:@cor; opacity:1; font-style:@style}
}

/* ALERTAS */

#lightbox {

  background:rgba(0,0,0,0.9); position:fixed; z-index:100000; width:100%; height:100%; top:0; left:0; display:none; opacity:0; .lato(14px,600,16px,#fff,normal,none);

  div {margin:0 auto; position:relative; width:80%; max-width:500px; top: 50%; transform:translateY(-50%); -ms-transform:translateY(-50%); -webkit-transform:translateY(-50%);}
  div p {padding:20px 40px; color:#fff; font-weight:600; font-size:14px; text-transform:uppercase; text-align:center;}
  
  a {display:block; position:absolute; top:-10px; right:-10px; height:40px; width:40px; line-height:32px; color:#fff; font-weight:bold; text-align:center; border:3px solid #fff; border-radius:30px; font-size:16px;}

  .sucesso {background:#6eaa53;}
  .sucesso a { background:#6eaa53;}

  .erro {background:#f5506b;}
  .erro a { background:#f5506b;}

}

/* ALERTAS */

#popupBanner
{
  background:rgba(0,0,0,0.9); position:fixed; z-index:100000; width:100%; height:100%; top:0; left:0; display:none; opacity:0;

  .wrapBanner {display:block; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); width: 1024px; max-width: 90%; border: 1px solid @rosa}
  .wrapBanner img {width: 100%;}

  .close {display: block; position: absolute; right: 0; top: -50px; color:#fff; font-size: 13px;}
  .close i { line-height: 40px;  vertical-align: middle; font-size: 16px; margin-left: 10px}
}

/* ELEMENTOS */

.titulosecao {

  margin-bottom:40px;

  a {display:inline-block; padding-bottom:7px; border-bottom:5px solid @rosa; .bebas(24px,400,24px,#fff,normal,uppercase);}

}

@media (max-width : 1024px)
{
}
@media (max-width : 992px)
{
}
@media (max-width : 480px)
{
}

@happyhour          : "\e804";
@private            : "\e800";
@sexydelivery       : "\e802";
@despedidasolteira  : "\e803";
@ensaiosensual      : "\e805";
@cursospalestras    : "\e801";

.btn {

  display:inline-block; height:40px; .lato(12px,700,36px,#fff,normal,uppercase); padding:0 20px; text-transform:uppercase; .transition();

  &.plus {padding:0 40px;}

  i {margin-right:10px; font-size:16px;}

  &.rosa.fill {background:@rosa; line-height:40px}
  &.rosa.line {border:2px solid @rosa;}
  &.rosa.fill:hover {background:@rosaHover}
  &.rosa.line:hover {border:2px solid @rosaHover;}

  &.azul.fill {background:@azul; line-height:40px}
  &.azul.line {border:2px solid @azul;}
  &.azul.fill:hover {background:@azulClaro}
  &.azul.line:hover {border:2px solid @azulClaro;}
  
  &.azulEscovado.fill {background:@azulEscovado; line-height:40px}
  &.azulEscovado.line {border:2px solid @azulEscovado;}
  &.azulEscovado.fill:hover {background:@azulClaro}
  &.azulEscovado.line:hover {border:2px solid @azulClaro;}

  &.branco.fill {background:#fff;}
  &.branco.line {border:2px solid #fff;}
  &.branco.fill:hover {color:@rosa}
  &.branco.line:hover {color:@rosa;}

  @media (max-width : 680px)
  {
    & {height:36px; line-height:32px;}
    &.rosa.fill {line-height:36px}
    &.plus {padding:0 30px;}
  }

}

.pagenavi
{
  border-top:1px solid rgba(255,255,255,0.1); padding-top:40px; margin:20px 0; text-align:center;

  a, span {display:inline-block; height:40px; line-height:40px; font-size:14px; text-transform:uppercase; background:rgba(255,255,255,0.1); margin:0 1px; width:40px; color:#888}

  span.current {width:40px; background:@rosa; color:#fff; font-weight:700}
}
