/* --------------------------------------------------------------
IMPORTS
----------------------------------------------------------------- */

@import 'bootstrap.css';
@import 'swiper.min.css';
@import 'library.less';

/* --------------------------------------------------------------
THEME DEFAULT STYLES
----------------------------------------------------------------- */

body {background:url(../images/bg-body.png); .lato;}

#master {position:relative; height:100%; padding-top:70px; z-index:0; clear:both;}

@media (max-width : 1024px)
{
	#master {padding-top:60px;}
}
@media (max-width : 540px)
{
	#master {padding-top:50px;}
}


/* --------------------------------------------------------------
HEADER
----------------------------------------------------------------- */

body > header
{
	background-color: rgba(@azul, 0.9); position:fixed; width:100%; z-index:2; height:70px;

	h1 {margin-top:10px; float:left; height:50px;}
	h1 img {width:auto; height:100%;}

	h1 img.desktop {display: block;}
	h1 img.mobile {display: none;}

	.contentTop {float:right; height:70px; position:relative; z-index:1}

	.social
	{
		float:left; height:40px; .middle; padding:0 10px; border-right:1px solid rgba(255,255,255,0.1);

		li {display:inline-block; padding:0 10px; height:40px;}
		li a {.lato(14px,400,40px,#fff,normal,none); .transition();}
		li a i {font-size:20px; .transition();}
		li:hover a i {color:@rosaHover}
	}

	.eventos {float:left; padding:0 20px; border-right:1px solid rgba(255,255,255,0.1); .middle;}

	.linksRapidos
	{
		float:left; height:70px; padding:0 20px 0 10px; line-height:17px;

		li {display:inline-block; padding:0 10px; height:70px; .transition(); border-bottom:4px solid rgba(237,23,133,0);}
		li a {.lato(14px,400,70px,#fff,normal,none);}
		li a i {font-size:20px; .transition();}
		li:hover {border-bottom:4px solid @rosaHover;}
	}

	.busca
	{
		float:left; padding:10px 15px 0 0; position:relative;

		a {display:block; width:50px; height:50px; background:rgba(255,255,255,0.1); text-align:center;}
		a i {line-height:47px; font-size:18px; color:#fff}

		form {position:absolute; display:none;}
	}

	.actions
	{
		position:relative; top:10px; float:right; width:50px; border-bottom:4px solid @rosa; height:60px;

		a {position:absolute; height:50px; width:50px; display:block; .transition();}
		a i {color:#fff; width:50px; height:50px; text-align:center; line-height:50px; font-size:28px; z-index:0}
		a.activated {z-index:1;}
		a.open {background:@rosa;}
		a.open:hover {background:@rosaHover;}
		a.close {background:@cinzaEscuro;}
		a.close:hover {background:@cinza;}
	}

	nav
	{
		position:fixed; top:70px; width:100%; background:@azul; border-radius:0 0 5px 5px; text-align:right; height:0; overflow:hidden;

		.container {padding-top:40px; padding-bottom:40px;}

		ul:not(.secondary-menu) {border-right:1px solid rgba(255,255,255,0.1); padding-right:30px;}
		ul:not(.secondary-menu) > li {min-height:60px;}
		ul:not(.secondary-menu) > li > a {.bebas(35px,400,60px,#fff,normal,uppercase); display:inline-block; position:relative;}
		ul:not(.secondary-menu) > li > a:before {display:block; position:absolute; width:100%; height:5px; left:0; bottom:5px; .transition(); content:""}
		ul:not(.secondary-menu) > li > a:hover:before {background:rgba(255,255,255,0.2);}
		ul:not(.secondary-menu) > li.current-menu-item > a:before {background:@rosa;}

		ul.secondary-menu li
		{
			a {.bebas(20px,400,40px,#fff,normal,none); height:40px; display:block; position:relative; padding-right:37px; .transition();}
			a:after {content:" "; display:block; width:22px; position:absolute; right:0; top:50%; transform:translateY(-51%); text-align:center;}

			&.happyhour a:after {.fontclube(@happyhour,28px,@rosa);}
			&.private a:after {.fontclube(@private,28px,@rosa);}
			&.sexydelivery a:after {.fontclube(@sexydelivery,26px,@rosa);}
			&.despedidasolteira a:after {.fontclube(@despedidasolteira,26px,@rosa);}
			&.ensaiosensual a:after {.fontclube(@ensaiosensual,26px,@rosa);}
			&.cursospalestras a:after {.fontclube(@cursospalestras,26px,@rosa);}

		}

		.info {clear:both; text-align:right; padding-top:10px; border-right:1px solid rgba(255,255,255,0.1); padding-right:30px}
		.info a {display:inline-block; margin:0 0 0 5px; .lato(16px,400,26px,#fff,normal,none);}
		.info a.mail {font-weight:600;}
		.info a i  {display:none; width:40px; height:40px; line-height:40px; color:#fff; font-size:26px; margin-left:5px}

		.social {float:none; border:0; text-align:right; padding:30px 0 20px 0; position:relative; right:-5px; display:none;}
		.social i {width:30px; height:40px; line-height:40px; color:#fff; font-size:24px !important; text-align:center;}
	}

	@media (max-width : 1024px)
	{
		nav ul:not(.submenu) > li > a {font-size:26px;}
		nav .menu-terciario-container ul:not(.submenu) {border:0}
		nav ul.secondary-menu li {min-height:inherit; padding:10px 0}
		nav ul.secondary-menu li a {height:auto; line-height:26px;}	
	}
	@media (max-width : 992px)
	{
		.linksRapidos {display:none;}
		nav .container {padding-bottom:40px;}
		nav ul:not(.submenu) {padding-right:15px;}
		nav ul:not(.submenu) > li {min-height:52px;}
		nav ul:not(.submenu) > li > a {font-size:22px; line-height:52px;}
		nav ul.submenu li a {font-size:14px; letter-spacing:1px; padding-right:27px; height:36px; line-height:36px;}
		nav ul.submenu li a:after {font-size:20px !important; width:16px; right:0}
		nav ul.secondary-menu li {min-height:inherit; padding:10px 0}
		nav ul.secondary-menu li a {height:auto; line-height:22px;}		
		nav .info {padding-right:15px; padding-top:15px; border:0}
		nav .info a span {display:none;}
		nav .info a i {display:inline-block;}
	}
	@media (max-width : 740px)
	{
		& {height:60px;}
		h1 {height:40px;}
		h1 img.desktop {display: none;}
		h1 img.mobile {display: block;}
		.contentTop {height:60px;}
		.contentTop .social {display:none;}
		.busca {padding-top:5px;}
		.actions {top:5px; height:58px}
		nav {top:60px; overflow:auto; height:~"calc(100% - 60px)"; width:320px; right:-320px}
		nav .container {padding-top:20px; padding-bottom:20px}
		nav ul:not(.submenu) {padding-right:10px; border:0}
		nav ul:not(.submenu) > li > a {font-size:28px;}
		nav ul.submenu {border-top:1px solid rgba(255,255,255,0.1); border-bottom:1px solid rgba(255,255,255,0.1); padding-top:15px; padding-bottom:10px; margin:10px 0;}
		nav ul.submenu li a {font-size:16px; letter-spacing:1px; padding-right:27px; height:inherit; line-height:18px; padding-top:10px; padding-bottom:10px;}
		nav ul.submenu li a:after {font-size:20px !important; width:16px; right:0}
		nav ul.secondary-menu li {min-height:inherit; padding:10px 0}
		nav ul.secondary-menu li a {height:auto; line-height:22px;}
		nav .social {display:block;}
	}
	@media (max-width : 540px)
	{
		& {height:50px;}
		h1 {height:30px;}
		.contentTop {height:50px;}
		.busca {display:none;}
		.eventos {padding:0 20px 0 0; border:0}
		.eventos .btn.rosa.line {padding:0 15px; height:36px; line-height:32px;}
		.actions {height:48px; width:40px;}
		.actions a {width:40px; height:40px;}
		.actions a i {width:40px; height:40px; line-height:40px; font-size:24px}
		nav {top:50px; height:~"calc(100% - 50px)";}
		nav ul:not(.submenu) {padding-right:5px; border:0}
		nav ul:not(.submenu) > li {min-height:44px;}
		nav ul:not(.submenu) > li > a {font-size:20px; line-height:44px}
		nav ul.submenu li a {font-size:14px; letter-spacing:1.5px}
		nav ul.secondary-menu li {min-height:inherit; padding:10px 0}
		nav ul.secondary-menu li a {height:auto; line-height:22px;}		
	}
	@media (max-width : 320px)
	{
		.eventos {padding:0 10px 0 0; border:0}
		.eventos .btn.rosa.line {padding:0 10px; font-size:11px;}
	}
}


/* --------------------------------------------------------------
BANNERS
----------------------------------------------------------------- */

/* BANNER HOME
----------------------------- */

#bannerHome
{
	position:relative; width:100%; height:100%; overflow:hidden;

	.swiper-container {height:100%; width:100%; z-index:0;}

	.swiper-slide {.bgCover();}

	.container, .row {height:100%; position:relative;}

	.legenda
	{
		position:absolute; bottom:60px; border-left:8px solid @rosa; padding:2px 0 2px 25px; margin-left:15px;

		h3 {.bebas(66px,400,68px,#fff,normal,none); border-bottom:1px solid rgba(255,255,255,0.1); padding-bottom:15px;}
		p {.lato(22px,300,24px,@cinza,normal,none); padding:17px 0 20px 0;}

		.buttons {padding-top:60px; clear:both; text-align:center;}
	}

	.creditos {position:absolute; right:110px; bottom:70px; font-size:13px; color:@cinzaEscuro}

	.navigation
	{
		position:absolute; z-index:1; bottom:70px; right:50%; margin-right:-571px; width:90px; text-align:right;

		span {display:inline-block; width:12px; height:12px; border:2px solid #fff; margin:0 2px; opacity:1}
		span.swiper-pagination-bullet-active {background:#fff;}
	}

	@media (max-width : 1024px)
	{
		.legenda {bottom:30px;}
		.legenda h3 {font-size:52px; line-height:52px; padding-bottom:10px;}
		.legenda p {font-size:18px; line-height:20px; padding:12px 0 20px 0}
		.creditos {bottom:40px; right:140px;}
		.navigation {margin-right:0; right:30px; bottom:40px}
	}
	@media (max-width : 992px)
	{
		& {height:60%;}
		.legenda h3 {font-size:46px; line-height:46px; padding-bottom:8px;}
		.legenda p {font-size:16px; line-height:18px; padding:10px 0 20px 0}
		.creditos {bottom:55px; right:30px;}
		.navigation {bottom:30px}
	}
	@media (max-width : 740px)
	{
		& {height:100%;}
		.legenda {max-width:70%}
		.legenda h3 {font-size:38px; line-height:38px; padding-bottom:8px;}
		.legenda p {padding:10px 0 20px 0}
	}
	@media (max-width : 540px)
	{
		& {height:60%;}
		.legenda {max-width:inherit;}
		.legenda h3 {font-size:30px; line-height:30px;}
		.legenda p {font-size:14px; line-height:16px; padding:8px 0 20px 0}
	}
	@media (max-width : 320px)
	{
		.legenda {padding:2px 0 2px 15px; margin-left:0; bottom:20px;}
		.legenda h3 {border:0; padding-bottom:15px;}
		.legenda p {display:none;}
		.creditos {bottom:40px; right:15px;}
		.navigation {bottom:18px; right:15px;}
	}
}

#bannerInternas
{
	position:relative; width:100%; height:500px; overflow:hidden; .bgCover();

	.container, .row {position:relative; height:100%;}

	.legenda {position:absolute; bottom:50px;}
	.legenda h2 {.bebas(50px,400,46px,#fff,normal,none); border-left:8px solid @rosa; padding:5px 0 0 25px;}
	.legenda h2 a {color:#fff;}

	.legendaServicos h2 {.bebas(50px,400,46px,#fff,normal,none); border-left:0; padding:5px 0 0 25px;padding-left:0;
		i{
			color:@rosa;
		}
	}

	@media (max-width : 1024px)
	{
		& {height:400px;}
	}
	@media (max-width : 992px)
	{
		& {height:400px;}
		.legenda {bottom:40px;}
	}
	@media (max-width : 740px)
	{
		& {height:100%;}
		.legenda {bottom:30px;}
		.legenda h2 {font-size:40px; line-height:36px; padding:5px 0 0 20px;}
	}
	@media (max-width : 540px)
	{
		& {height:280px;}
		.legenda {bottom:30px;}
		.legenda h2 {font-size:30px; line-height:26px; padding:5px 0 0 15px;}
	}
	@media (max-width : 320px)
	{
		& {height:240px;}
		.legenda h2 {font-size:26px; line-height:22px; padding:5px 0 0 15px;}
	}
}


/* --------------------------------------------------------------
HOME
----------------------------------------------------------------- */

/* INTRO
------------------------------------- */

#intro
{
	padding:60px 0; background-color: rgba(@azul, 0.5);

	h3 {.lato(36px,300,38px,#d8d7d7,normal,none); border-right:1px solid @rosa; padding:3px 85px 3px 0; margin-right:30px;}

	p {.lato(16px,300,24px,@cinzaEscuro,italic,none);}

	@media (max-width : 1024px)
	{
		& {padding:40px 0;}
	}
	@media (max-width : 992px)
	{
		h3 {border:0; padding-right:40px; padding-bottom:20px; margin:0;}
	}
	@media (max-width : 540px)
	{
		h3 {font-size:30px; line-height:32px}
	}
	@media (max-width : 320px)
	{
		h3 {font-size:26px; line-height:28px}
	}
}

/* GALLERY
------------------------------------- */

#gallery
{
	padding:10px 0; background:rgba(0,0,0,0.6); position:relative;

	.swiper-container {width:100%; z-index:0; height:500px;}

    .swiper-slide
    {
    	width:980px; background:rgba(0,0,0,0.5);

    	a {
				display: block;
				width: 100%;
				height: 100%;
				.bgCover();
				-webkit-filter:grayscale(100%);
				filter: grayscale(100%);
				.transition();
			}

    	&.swiper-slide-active a {-webkit-filter:none; filter:none;}
    }

    .navigation
    {
		position:relative; z-index:1; width:980px; margin:0 auto;

		span {display:block; width:77px; position:absolute; text-align:center; background:rgba(255,255,255,0.15); color:#676767; cursor:pointer; .transition();}
		span i {font-size:60px; height:500px; line-height:480px;}
		span:hover {background:#242225; color:#fff;}

		span.arrow-left {left:-78px;}
		span.arrow-right {right:-78px;}
    }

    @media (max-width : 1024px)
	{
		.swiper-container {height:400px;}
		.swiper-slide {width:~"calc(100% - 160px)";}
		.navigation {width:~"calc(100% - 164px)";}
		.navigation span.arrow-left {left:-72px}
		.navigation span.arrow-right {right:-72px}
		.navigation span i {font-size:50px; height:400px; line-height:380px;}
	}
	@media (max-width : 992px)
	{
		.swiper-slide {width:~"calc(100% - 134px)";}
		.navigation {width:~"calc(100% - 120px)";}
		.navigation span {width:60px;}
		.navigation span.arrow-left {left:-54px;}
		.navigation span.arrow-right {right:-54px;}
	}
	@media (max-width : 740px)
	{
		.swiper-container {height:360px;}
		.swiper-slide {width:~"calc(100% - 120px)";}
		.navigation {width:~"calc(100% - 120px)";}
		.navigation span.arrow-left {left:-53px;}
		.navigation span.arrow-right {right:-53px;}
		.navigation span i {font-size:40px; height:360px; line-height:340px;}
	}
	@media (max-width : 540px)
	{
		.swiper-container {height:260px;}
		.swiper-slide {width:~"calc(100% - 80px)";}
		.navigation {width:~"calc(100% - 80px)";}
		.navigation span {width:40px;}
		.navigation span.arrow-left {left:-40px;}
		.navigation span.arrow-right {right:-40px;}
		.navigation span i {font-size:30px; height:260px; line-height:240px;}
	}
	@media (max-width : 320px)
	{
		.swiper-container {height:220px;}
		.navigation span i {font-size:30px; height:220px; line-height:200px;}
	}
}

/* SERVIÇOS
------------------------------------- */

#servicosHome
{
	padding:60px 0 30px 0; background-color: rgba(@azul, 0.5);

	ul {display: flex; flex-flow: row wrap; justify-content: flex-start; align-items: stretch; clear: both;}

	li 
	{
		margin-bottom:30px; position: relative; 

		a
		{
			display: block; background:rgba(@azulEscuro, 1); position: relative; width: 100%; height: 100%; padding:25px 25px 12px 80px; .transition(); border:2px solid rgba(255,255,255,0.1);

			&:hover {border:2px solid rgba(255,255,255,1);}

			h3 {display:block; .lato(17px,600,20px,#fff,normal,none); position:relative;}
			
			p {.lato(14px,300,20px,@cinza,normal,none); padding-top:15px;}

			&:before {content:" "; display:block; width:60px; position:absolute; left:10px; top:23px; text-align:center;}

			&.happyhour:before {.fontclube(@happyhour,42px,@rosa);}
			&.private:before {.fontclube(@private,42px,@rosa);}
			&.sexydelivery:before {.fontclube(@sexydelivery,42px,@rosa);}
			&.despedidasolteira:before {.fontclube(@despedidasolteira,42px,@rosa);}
			&.ensaiosensual:before {.fontclube(@ensaiosensual,42px,@rosa);}
			&.cursospalestras:before {.fontclube(@cursospalestras,42px,@rosa);}
		}
	}

	@media screen and (max-width : 1024px)
	{
		& {padding:40px 0 10px 0;}
		li a {padding:12px 25px 0 70px;}
		li a h3 {font-size:15px;}
		li a p {padding-top:6px; height:84px; line-height:17px;}
		li a:before {width:50px; left:5px; font-size:38px !important}
	}
	@media screen and (max-width : 540px)
	{
		ul {display: block;}
		
		li {margin-bottom:15px !important;}
		li a {height:inherit; padding-bottom:12px;}
		li a p {height:inherit; line-height:20px;}
	}
}

/* EVENTOS
------------------------------------- */

#eventosHome {padding:60px 0; .bgCover();}

@media (max-width : 1024px)
{
	#eventosHome {padding:40px 0;}
}
@media (max-width : 740px)
{
	#eventosHome {padding:20px 0;}
}
@media (max-width : 540px)
{
	#eventosHome.interna {padding:20px 0 0 0;}
}

.listPostEventos
{
	li {margin-bottom:30px;}

	img {width:100%;}

	.content
	{
		padding:20px; background:rgba(@azulEscuro, 1);

		h3 {.bebas(36px,400,38px,#fff,normal,none); padding-bottom:15px;}
		h3 a {color:#fff}
		p {.lato(16px,300,22px,@cinza,normal,none); padding:20px 0;}

		time {display:block; color:#fff}
		time i {font-size:20px; margin-right:5px;}

		.addthis_inline_share_toolbox {float:right; padding-top:3px}
	}

	&.category
	{
		padding-top:20px;

		li {clear:both; overflow:hidden; padding-bottom:40px; margin-bottom:40px; border-bottom:1px solid rgba(255,255,255,0.1);}
		li:last-child {margin-bottom:30px; padding-bottom:0; border:0}

		.content {background:none; padding-top:0}
	}

	@media (max-width : 1024px)
	{
		.content h3 {font-size:28px; line-height:30px;}
		.content p {font-size:14px; line-height:20px; padding:15px 0 20px 0;}
		.content time i {font-size:16px; margin-right:5px;}
		&:not(.category) .content .addthis_inline_share_toolbox {float:none; clear:both; padding-top:20px}
	}
	@media (max-width : 992px)
	{
		&.category .content {padding-top:20px;}
	}
	@media (max-width : 540px)
	{
		.content h3 {font-size:24px; line-height:26px;}
		.content a.btn {display:block; clear:both; margin-bottom:5px; float:left;}
		.content .addthis_inline_share_toolbox {float:none; clear:both; padding-top:20px}
		&.category li {padding-bottom:15px;}
		&.category .content .addthis_inline_share_toolbox {float:none; clear:both; padding-top:20px}
	}
}


/* --------------------------------------------------------------
INTERNAS
----------------------------------------------------------------- */

#internas
{

	&:not(.light) {background:url(../images/bg-internas.png);}
	//&:not(.light) {background-color:rgba(13,13,63,0.5);}

	article.single-eventos
	{
		padding:80px 0; overflow:hidden;

		h1 {.lato(36px,300,38px,#fff,normal,none); padding-bottom:30px;}

		.evento {padding-right:30px}

		.intro
		{
			overflow:hidden; margin-bottom:30px;

			figure {width:250px; height:250px; .bgCover(); margin-right:40px; float:left;}
			figure img {display:none;}

			.content {width:~"calc(100% - 290px)"; height:250px; float:left;}

			time {display:block; .lato(16px,400,18px,#fff,normal,none);}

			p {.lato(22px,300,26px,#b8b8b8,italic,none); padding:20px 0;}
		}

		.post
		{
			clear:both; padding-bottom:30px;

			p {.lato(16px,300,22px,#cfcfcf,normal,none); padding:0 0 0 30px; margin-bottom:30px; border-left:5px solid @rosa;}
			p.noBorder {padding:0; border:0}
			p a {color: @rosa; text-decoration: underline;}

			img {max-width:100% !important; height:auto !important;}

			img {max-width:100% !important; margin-bottom:30px;}

			.addthis_inline_share_toolbox {clear:both; padding-top:20px}

			a.btn {margin:30px 20px 0 0;}
		}
	}
	.page-blog {
		padding: 80px 0;
		overflow: hidden;
		.postList{
			.postItem{
				width:auto;
				height:240px;
				background:@cinzapItem;
				margin:0 0 40px 0;

				&.full {height:auto; overflow:hidden; padding:10px 10px 30px 10px}

				.col{
					width:35%;
					height:100%;
					float:left;
					img{
						object-fit:cover;
						width:100%;
						height:100%;
						float:left;
					}
				}
				.piContent{
					width:65%;
					height:100%;
					float:left;
					padding:20px;

					&.full {width:100%; float:none;}

					h2{
						a {.bebas(28px, 400, 28px, @branco, normal, none); display:block; float:none;}
					}
					p{
						.lato(16px, 300, 20px, @cinza, normal, none);
						padding:25px 0;
					}
					a{
						font-size:16px;
						float:left;
					}
					.socialBar{
						float:left;
						margin:0 20px;
						.addthis_inline_share_toolbox {position:relative; top:4px;}
						.sbItem{
							.transition();
							.fontaws("", 20px, @branco);
							background:@cinza;
							padding:9px;
							margin:0 0 0 15px;
							&:hover{
								background:@rosa;
							}
						}
						.facebook{
							background:#3b579d;
							padding:9px 14px;
						}
						.twitter{
							background:#1da1f2;
							padding:9px 11px;
						}
						.plus{
							background:#ff6751;
							padding:9px 11px;
						}
					}
				}
				.picFull{
					width:100%;
				}
			}
			.paginacao{
				.wp-pagenavi{
					clear:both;
					padding-top:40px;
					span,a{
						.transition();
						padding:10px 15px;
						background:#282828;
						color:@branco;
						margin:0 1px;
						&:hover,&.current{
							background:@rosaHover;
						}
					}
				}
				.active{
					background:@rosa;
				}
			}
		}
		h1 {
			.lato(36px,300,38px,#fff,normal,none);
			padding-bottom: 30px;
		}
	}
	.page-blog-single{
		padding: 80px 0;
		overflow: hidden;
		img{
			width:100%;
		}
		article{
			width:100%;
			height:100%;
			background:@semiPreto;
			padding:20px 25px;
			h1{
				.bebas(34px, 400, 36px, @branco, normal, none);
				display: inline-block;
				position:relative;
				left:-30px;
				padding:8px 20px 8px 25px !important;
				margin:20px 0;
				padding-left:10px;
				background: @rosa;

				span {position:relative; top:2px; display:inline-block;}
			}
			time {display:block; color:#fff; font-size:14px; font-weight:300; padding-bottom:40px;}
			p{
				.lato(16px, 400, 22px, @cinza, normal, none);
				padding:0 0 20px 0;
			}
			img {max-width:100% !important; height:auto !important}
			ul,ol
			{
				li {position:relative; .lato(16px, 400, 20px, #fff, italic, none); padding:8px 0; padding-left:12px;}
				li:before {content:""; position:absolute; width:5px; height:5px; background:@rosa; border-radius:10px; left:0; top:17px;}
			}
			strong {color:#fff; font-weight:700;}
			a {color:@rosa}
			.socialBar{
				margin:40px 0;
				padding:0 0 40px 0;
				.addthis_inline_share_toolbox {position:relative; top:4px;}
				.sbItem{
					.transition();
					.fontaws("", 20px, @branco);
					background:@cinza;
					padding:9px;
					margin:0 15px 0 0;
					&:hover{
						background:@rosa;
					}
				}
				.facebook{
					background:#3b579d;
					padding:9px 14px;
				}
				.twitter{
					background:#1da1f2;
					padding:9px 11px;
				}
				.plus{
					background:#ff6751;
					padding:9px 11px;
				}
			}
			.facebookComment
			{
				h3 {.bebas(20px, 400, 20px, @branco, normal, none); padding:0 0 20px 0;}

				.faceBox {padding:10px 20px; background:#111111;}
			}
		}
	}
	.page-contato{
		padding: 80px 0;
		overflow: hidden;
		h3{
			.lato(36px, 300, 22px, @cinza, normal, none);
			padding-bottom:20px;
			margin:10px 0 0 0;
		}
		p{
			.lato(16px, 300, 18px, @cinza, normal, none);
			padding:20px 0;
		}
		p.contatoTel{
			.lato(22px, 300, 18px, @cinza, normal, none);
			padding:10px 0;
		}
		p.contatoEmail{
			.lato(22px, 400, 18px, @rosa, normal, none);
			padding:10px 0;
			padding-bottom:40px;
		}
	}
	.page-localizacao{
		padding: 80px 0;
		overflow: hidden;
		h3{
			.lato(36px, 300, 36px, @cinza, normal, none);
			padding-bottom:20px;
			margin:10px 0 0 0;
		}
		p{
			.lato(16px, 300, 18px, @cinza, normal, none);
			padding:20px 0;
		}
		.traceRota{
			form
			{
				padding-bottom:30px;

				fieldset {position:relative;}

				p {.lato(22px, 300, 22px, #fff, normal, none);}

				span {display:block; .lato(13px, 400, 16px, @cor : rgba(255,255,255,0.4), normal, none); padding-top:10px;}

				input{
					.lato(16px, 400, 50px, @branco, normal, none);
					width:100%;
					padding:0 60px 0 15px;
					height:50px;
					background:@cinzaDark;
				}
				button
				{
					position:absolute; top:0; right:0; width:60px; height:50px; cursor:pointer; background:transparent;

					i { text-align:center; line-height:50px; color:#fff; font-size:20px; .transition();}

					&:hover i {color:@rosa;}
				}
			}

			#map {width:100%; height:450px;}

			#trajeto-texto
			{
				padding-top:20px;

				.adp, .adp table {color:#b8b8b8}

				.adp-placemark {background:@cinzaDark; color:#fff!important; border:0; font-size:16px; font-weight:400;}

				img.adp-marker2 {width:18px; height:50px; margin:0 15px;}
			}
		}
	}
	.page-quemsomos{
		padding: 80px 0;
		overflow: hidden;
		h3{
			.lato(36px, 300, 36px, @cinza, normal, none);
			padding-bottom:20px;
			margin:10px 0 0 0;
		}
		p{
			.lato(16px, 300, 18px, @cinza, normal, none);
			padding:20px 0;
		}
	}
	.page-single-servicos{
		padding: 80px 0;
		overflow: hidden;
		h2{
			.lato(36px, 300, 36px, #fff, normal, none);
			padding-bottom:30px;
			margin:10px 0 0 0;
		}
		h3{
			.lato(28px, 300, 28px, @cinza, normal, none);
			padding-bottom:20px;
			text-align:center;
		}
		p{
			.lato(16px, 400, 22px, @cinza, normal, none);
			padding:0 0 20px 0;

			a {color:@rosa}
		}
		.content {
			ul,ol
			{
				li {position:relative; .lato(16px, 400, 20px, #fff, italic, none); padding:8px 0; padding-left:12px;}
				li:before {content:""; position:absolute; width:5px; height:5px; background:@rosa; border-radius:10px; left:0; top:17px;}
				li h3 {.bebas(24px,400,28px,#fff,normal,none); text-align:left;}
			}
		}

		.depoimentos-parceiros
		{
			padding:80px 0;

			ul.listPosts li.firstList
			{
				overflow:hidden; padding:40px 0; border-top:1px solid rgba(255,255,255,0.1); clear:both;
				
				&:first-child {border:0; padding-top:0}
				&:before {content:none;}

				ul {padding-bottom:15px;}

				figure
				{
					.bgIMG {position:relative; width:100%; height:200px; .bgCover();}
					.bgIMG.contain {.bgContain(); background-position:center;}
					img {display:none;}
				}

				.content {position:relative;}

				i {display:block; font-size:24px; color:@rosa; position:relative; margin-bottom:5px;}

				p {.lato(16px,300,22px,@cinza,italic,none)}

				a {font-weight:700; font-size:16px; font-style:normal;}

				.footer
				{
					.lato(14px,300,20px,#535353,normal,none);

					strong {display:block; .lato(15px,700,16px,#fff,normal,none)}
				}
			}

			@media (max-width : 1024px)
			{
				& {padding:40px 0;}
			}

			@media (max-width : 740px)
			{
				& {padding:0;}
			}
			@media (max-width : 540px)
			{
				ul.listPosts li.firstList figure {margin-bottom:20px;}
			}
			
		}
	}

	.ensaios
	{
		overflow: hidden; clear:both; margin-top:60px;

		ul {padding:0 10px;}

		li
		{
			padding:5px;

			a {display:block; position:relative;}

			span {display:none;}

			@media (min-width : 1280px)
			{
				span {position:absolute; z-index:1; top:0; height:0; width:100%; height:100%; background:rgba(237,23,133,0.6); text-align:center; display:block; opacity:0; .transition();}
				span i {color:#fff; font-size:24px; .middle;}

				a:hover span {opacity:1;}
			}

			img {width:100%; position:relative; z-index:0}
		}
	}
	.espacos
	{
		overflow:hidden; clear:both; margin-top:40px;

		h3 {.lato(28px,700,32px,#fff,normal,none); padding-top:40px; padding-bottom:30px; text-align:left; border-top:1px solid rgba(255,255,255,0.1);}

		@altura : 370px;

		ul {padding-top:20px;}

		li
		{
			clear:both; overflow:hidden; padding:40px 0; text-align:right;

			.gallery
			{
				position:relative; float:right;

				.swiper-container
				{
					height:@altura; width:100%; position:relative; z-index:0;

					.swiper-slide {height:100%;}
					.swiper-slide a {display:block; width:100%; height:100%; .bgCover(); position:relative;}

					.legend {position:absolute; padding:20px; bottom:0; width:100%; background:rgba(0,0,0,0.8); .lato(16px,300,22px,#fff,normal,none);}
				}

				.navigation span {position:absolute; top:0; width:60px; height:100%; z-index:1; cursor:pointer; .transition();}
				.navigation span:hover {background:rgba(255,255,255,0.2);}
				.navigation span i {color:#fff; text-align:center; .middle; font-size:50px;}

				.navigation span.arrow-left {left:15px;}
				.navigation span.arrow-right {right:15px;}
			}

			.content
			{
				height:@altura; float:left;

				h4 {display:inline-block; margin-bottom:30px; padding-bottom:7px; border-bottom:5px solid @rosa; .bebas(24px,400,24px,#fff,normal,uppercase);}

				p {padding-bottom:20px; .lato(16px,300,22px,#fff,normal,none);}

				p a {color: @rosa; text-decoration: underline;}
			}

			&:nth-child(odd)
			{
				background-color:rgba(0,0,0,0.2); text-align:left;

				.gallery {float:left;}
				.content {float:right;}
			}
		}

		.atracoes
		{
			padding:50px 0;

			h3 {padding:0 0 30px 0; border:0;}

			.swiper-slide
			{
				position:relative;

				a {display:block; width:100%; height:180px; position:relative; .bgCover();}
				a .label {position:absolute; z-index:1; top:0; height:0; width:100%; height:100%; background:rgba(237,23,133,0.5); text-align:center; .transition(); .bebas(24px,400,24px,#fff,normal,uppercase); padding:0 10px}
				a:hover .label {opacity:1;}
			}

			.navigation
			{
				padding-top:15px; text-align:center;

				span {width:12px; height:12px; margin:0 2px; opacity:1; background:rgba(255,255,255,0.2);}
				span.swiper-pagination-bullet-active {background:@rosa}
			}
		}
	}

	#formularios
	{
		overflow:hidden; /*background:rgba(0,0,0,0.1);*/ clear:both;

		p {.lato(22px,300,24px,#fff,normal,none); padding-bottom:25px}

		form:not(.vfbp-form)
		{
			.input {overflow:hidden; padding-bottom:20px;}
			.input.full {width:100%; clear:both;}
			.input.half {width:~"calc(50% - 10px)"}

			label {display:none;}

			input[type="text"],
			input[type="email"] {
				width: 100%;
				height: 50px;
				.lato(16px,400,50px,#fff,normal,none);
				padding: 0 15px;
				background: rgba(255,255,255,0.1);
				border-radius: 5px;
				margin: 10px 0;
			}

			.select {
				width: 100%;
				background: rgba(255,255,255,0.1) url("../images/arrow-seclts-form.png") no-repeat right 15px center;
				border: 1px solid rgba(0,0,0,0.1);
				border-radius: 5px 0 0 5px;
				margin:10px 0;
			}

			.select select {
				width: ~"calc(100% + 20px)";
				height: 50px;
				.lato(16px,400,50px,@cor : rgba(0,0,0,0.8),normal,none);
				padding: 0 15px;
				background: transparent;
			}

			textarea {width:100%; height:200px; .lato(16px,400,18px,#000,normal,none); padding:10px 15px; background:rgba(255,255,255,0.1); border:1px solid rgba(0,0,0,0.1);  border-radius:5px;margin:10px 0;}

			.captcha {

				text-align:right;

				label {font-size:14px; line-height:40px; display:inline-block;}
				label strong {color:#fff; font-size:17px;}

				input:not(.submit) {width:50px; text-align:center; margin:0 10px; font-style:normal; font-size:18px;}


				img {width:50px; height:50px; margin:0 30px; display:inline-block; vertical-align:middle; display:none;}

			}

			input.submit {
				width: 120px;
				display: inline-block;
				background: @rosa;
				color: #fff;
				cursor: pointer;
				.lato(14px,700,50px,#fff,normal,uppercase);
				.transition();
				border-radius: 5px;
				&.full{
					width:50%;
					left:0;
					margin-left:25%;
					@media (max-width : 540px){
						&{
							width:100%;
							margin:0;
						}
					}
				}
			}

			input.submit:hover {
				box-shadow: 0 0 10px rgba(0,0,0,0.3);
				background: @rosaHover;
			}

			.input.fone
			{
				.ddd {width:50px; padding:0; text-align:center; float:left; margin-right:5px;}
				.numero {width:~"calc(100% - 55px)";}
			}

			@media (max-width : 540px)
			{
				.input.half {width:100%; clear:both !important; float:none !important;}
				.captcha input:not(.submit) {margin-right:0}
				.captcha input.submit {width:100%; display:block; clear:both; margin-top:20px;}
				.captcha img {margin:0 auto; margin-top:20px;}
			}
		}

		.vfbp-form {

			padding:0; position:relative; clear:both; overflow:hidden;

			input[type="text"],
			input[type="email"]
			{
				width: 100%;
				height: 50px;
				.lato(16px,400,50px,#fff,normal,none);
				padding: 0 15px;
				background: rgba(255,255,255,0.1);
				margin: 10px 0;
				border:0;
				border-radius:0;
			}

			label {color:@cinza}

			.vfb-form-group {padding-top:10px; margin-bottom:0}

			.vfb-fieldType-text label,
			.vfb-fieldType-email label,
			.vfb-fieldType-date label,
			.vfb-fieldType-select label,
			.vfb-fieldType-textarea label {display:none;}

			.vfb-fieldType-captcha label {display:none;}

			.vfb-form-control {height:50px; .lato(14px,500,50px,@cinzaEscuro,normal,uppercase); background:#f2f2f2; border:1px solid #d6d6d6; box-shadow:none;}

			.vfb-fieldType-select {

				padding-top:20px;

				label {margin-bottom:10px;}

				.vfb-form-group {padding-top:0; background: rgba(255,255,255,0.1);}
				.vfb-form-group select {background:none; border:0; color:#666; text-transform:none;}

			}

			.vfb-fieldType-checkbox, vfb-fieldType-radio {padding-top:14px}

			textarea.vfb-form-control {width:100%; height:150px; .lato(16px,400,18px,#000,normal,none); padding:10px 15px; background:rgba(255,255,255,0.1); border:1px solid rgba(0,0,0,0.1);  border-radius:5px;margin:10px 0;}

			.vfb-fieldType-captcha {border-top:1px solid rgba(255,255,255,0.1); padding-top:20px; margin-top:20px;}

			.vfb-fieldType-submit {padding-top:20px; border-top:1px solid rgba(255,255,255,0.1); margin-top:20px;}
			.vfb-fieldType-submit button {height:50px; padding:0 20px; .lato(14px,500,50px,#fff,normal,uppercase); width:100%; background:@rosa; .transition(); border:0; position:relative; top:22px;}
			.vfb-fieldType-submit button:hover {background:@rosa;}

		}

	}

	aside
	{

		padding-left:45px; overflow:hidden; position:relative;

		&:before {content:""; display:block; position:absolute; width:1px; height:100%; border-left:1px dashed rgba(255,255,255,0.1); left:20px;}

		.widgets
		{
			overflow:hidden; padding-left:15px;

			h2 {.lato(24px,300,36px,#fff,normal,none); padding-bottom:30px;}
			h2 strong {.bebas(36px,400,36px,@rosa,normal,none); display:block;}

			&.eventos
			{
				padding-bottom:40px; clear:both;

				h2 {padding-bottom:10px;}

				li {padding:20px 0; border-bottom:1px solid rgba(255,255,255,0.1); clear:both; overflow:hidden;}

				img {float:left; width:128px; margin-right:15px;}

				p {width:~"calc(100% - 143px)"; float:left;}
				p.full {width:100%; float:none;}
				p strong {.bebas(20px,400,20px,#fff,normal,none); display:block; padding-bottom:5px;}
				p time {display:block; .lato(14px,300,14px,#a7a7a7,normal,none);}
			}

			&.servicos
			{
				padding-bottom:40px; clear:both;

				&.page {padding-bottom:0;}

				li {margin-bottom:2px;}
				li a
				{
					background-color:rgba(@azul,0.6); display:block; .lato(13px,400,16px,#cfcfcf,italic,none); position:relative; height:40px; padding:0 10px 0 0;

					i {display:block; float:left; width:40px; height:40px; text-align:center; line-height:40px; vertical-align:middle; color:@rosa; font-size:22px;}

					&:hover {background:rgba(@azulEscuro,0.6); color:#fff;}
					&:hover:before {content:""; position:absolute; right:10px; .fontaws("\f054",14px,#fff); top:50%; transform:translateY(-50%);}
				}
			}

			&.categorias
			{
				padding-bottom:40px; clear:both;

				li {margin-bottom:2px;}
				li a
				{
					background-color:rgba(@azulEscuro,0.6); display:block; .lato(14px,400,50px,#cfcfcf,italic,none); position:relative; height:50px; padding-left:10px;

					i {display:block; float:left; width:50px; height:50px; text-align:center; line-height:50px; vertical-align:middle; color:@rosa; font-size:24px;}

					&:hover {background:rgba(@azulEscuro,1); color:#fff;}
					&:hover:before {content:""; position:absolute; right:10px; .fontaws("\f054",14px,#fff); top:50%; transform:translateY(-50%);}
				}
			}
		}
	}
}

.orcamento{
	background:rgba(0, 0, 0, 0.1);
	padding:20px 0;
	clear:both;
	margin-top:80px;
	margin-bottom:80px;

	h3 {.lato(22px,300,22px,#fff,normal,none); padding-bottom:20px; padding-top:10px; text-align:center;}
}

.noPad{
	padding:0 !important;
}
.noMarginTop{
	margin-top:0 !important;
}
.mTop12{
	margin-top:12px;
}
.noBorder{
	border:0;
}
.alignMiddle{
	vertical-align:middle;
}


/* --------------------------------------------------------------
FOOTER
----------------------------------------------------------------- */

/* COMPLEMENTOS
------------------------------------- */

#complemento
{
	position:relative; overflow:hidden; padding:60px 0;

	.container, .row {height:100%; position:relative;}

	.blog
	{
		.slide {position:relative;}

		.swiper-container {height:340px; width:100%; z-index:0;}

		.swiper-slide
		{
			.bgCover();

			a {display:block; width:100%; position:absolute; bottom:10px; border-left:8px solid @rosa; padding:2px 0 40px 25px; margin-left:10px;}

			h3 {.bebas(32px,400,36px,#fff,normal,none); border-bottom:1px solid rgba(255,255,255,0.1); padding-bottom:5px; display:inline-block; max-width:~"calc(100% - 160px)";}
		}

		.navigation
		{
			position:absolute; z-index:1; bottom:7px; left:0; padding:0 15px; width:100%;

			.balls
			{
				padding-left:28px; height:40px; line-height:40px; float:left;

				span {display:inline-block; width:12px; height:12px; border:2px solid #fff; margin:0 2px; opacity:1}
				span.swiper-pagination-bullet-active {background:#fff;}
			}

			.arrows
			{
				float:right; padding-right:0;

				span {display:inline-block; width:40px; text-align:center; background:transparent; color:rgba(255,255,255,0.7); cursor:pointer; .transition(); margin:0 5px}
				span i {font-size:30px; height:40px; line-height:40px;}
				span:hover {color:#fff}
			}
		}

		.footer {padding-top:40px;}
	}

	.depoimentos
	{
		position:relative;

		.fa-quote-left {height:55px; color:#fff; font-size:40px;}

		.swiper-container {height:260px; width:100%; z-index:0;}

		.swiper-slide {height:100%;}

		cite {.middle; .lato(18px,300,26px,#fff,italic,none);}
		cite span {display:block; .lato(16px,700,16px,#7d7d7d,normal,none); padding-top:15px;}
		cite span i {vertical-align:middle; font-size:12px; margin-right:5px; position:relative; top:-4px}

		.footer {padding-top:65px; position:relative;}

		.navigation
		{
			position:absolute; top:8px;

			span {display:inline-block; width:12px; height:12px; border:2px solid #fff; margin:0 2px; opacity:1}
			span.swiper-pagination-bullet-active {background:#fff;}
		}

		&:after {content:""; display:block; position:absolute; width:1px; height:423px; background:rgba(255,255,255,0.1); left:-48px; top:76px;}
	}

	@media (max-width : 992px)
	{
		& {padding:40px 0 0 0;}

		.depoimentos .swiper-container {height:inherit; margin-bottom:20px;}
		.depoimentos cite {.middleOff;}
		.depoimentos {margin-top:40px; padding-top:40px; padding-bottom:40px; background:rgba(0,0,0,0.4)}
	}
	@media (max-width : 540px)
	{
		.blog .swiper-container {height:280px;}
		.blog .swiper-slide a {padding:2px 0 40px 15px; margin-left:10px;}
		.blog .swiper-slide h3 {font-size:24px; line-height:28px; max-width:~"calc(100% - 40px)";}
		.depoimentos cite {font-size:16px; line-height:20px}
		.depoimentos cite span {font-size:14px; line-height:14px}
	}
}

/* RODAPÉ
------------------------------------- */

body > footer
{
	.informacoes
	{
		padding:40px 0; background:rgba(@azulEscuro, 0.8);

		address
		{
			float:left;

			span {display:block; .lato(22px,300,26px,#fff,normal,none); padding-bottom:10px}

			a.mapa {.lato(16px,500,16px,@rosa,normal,none); display:inline-block;}
			a.mapa i {margin-right:5px; color:@rosa; font-size:16px;}
		}

		ul {float:right; text-align:right;}
		ul li {padding:0;}
		ul li a {.lato(17px,500,30px,#fff,normal,none);}
		ul li a i {font-size:18px; color:@rosa; margin-right:5px;}
	}

	.rodape
	{
		height:50px; background:rgba(@azul, 1);

		.copy {.lato(13px,400,50px,#777777,normal,none);}

		.dev
		{
			text-align:right;

			ul {float:right;}
			ul li {display:inline-block; padding:0 20px; line-height:50px;}
			ul li a {.lato(13px,400,18px,#d8d8d8,normal,none); display:inline-block; height:18px; position:relative;}
			ul li a:after {content:""; width:1px; height:18px; background:rgba(255,255,255,0.15); right:-22px; top:0; position:absolute;}
			ul li a:hover {text-decoration:underline;}

			a.prog {display:block; float:right; margin-left:30px}
		}
	}

	@media (max-width : 992px)
	{
		.informacoes address {float:none; text-align:center; margin-bottom:30px;}
		.informacoes address span {font-size:18px;}
		.informacoes ul {float:none; text-align:center;}
		.rodape {height:inherit; padding-top:10px}
		.rodape .copy {width:100%; float:none; padding:10px 40px; line-height:16px; text-align:center;}
		.rodape .dev {text-align:center; width:100%; padding:0; float:none;}
		.rodape .dev ul {display:block; float:none; width:100%;}
		.rodape .dev ul li:last-child a:after {content:none;}
		.rodape .dev a.prog {display:inline-block; float:none; clear:both; margin-left:0}
	}
}

.fancybox-caption {text-align:center;}

/* -----------------------------------------
AJUSTES DE RESPONSIVO
----------------------------------------- */

@media only screen and (max-width : 1090px){
	.listPostEventos{
		.content{
			.addthis_inline_share_toolbox{
				float:left;
				display:contents;
			}
			.btn.rosa.fill{
				margin-bottom:10px;
			}
		}
	}

	#bannerHome .navigation{
		right:60%;
	}

	#internas{
		.page-blog{
			.postList{
				.postItem{
					.col{
						margin-right:10px;
					}
					.piContent{
						display:contents;
						h2{
							a{
								padding-top:10px;
							}
						}
					}
				}
			}
		}
	}
	#internas article.single-eventos {padding:40px 0 20px 0;}
	#internas article.single-eventos .intro p {font-size:18px; line-height:22px;}
	#internas aside {padding-left:0;}
	#internas aside:before {left:0}
	#internas .page-blog {padding:40px 0 20px 0}
	body > footer .rodape .copy {font-size:12px;}
	#internas .page-localizacao {padding:40px 0;}
	#internas .page-single-servicos {padding-top:40px; padding-bottom: 40px;}
	.orcamento {margin-top:40px}
}

@media only screen and (max-width : 992px){
	#internas{
		article.single-eventos{
			.intro{
				display:contents;
			}
			.post{
				padding-top:40px;
			}
		}
		.espacos{
			li{
				.gallery{
					width:100%;
					.swiper-container{
						.swiper-slide{
							&:style{
								width:100%;
							}
						}
					}
				}
			}
		}
	}
	#internas aside {padding-top:40px; border-top:1px dashed rgba(255,255,255,0.2); margin-top:20px}
	#internas aside:before {content:none;}
	#internas .espacos li {padding-bottom:20px;}
	#internas .espacos li .content {height:inherit;}
	#internas .espacos li .content .middle {.middleOff; padding-top:30px}
	#internas .espacos li:nth-child(2) {text-align:left;}
	#internas .espacos .atracoes {padding:30px 0;}
	#internas .formContato {padding:40px 0 0 0;}
}

@media only screen and (max-width : 767px){
	#internas{
		#formularios{
			.vfbp-form{
				.vfb-fieldType-submit{
					button{
						top:0;
					}
				}
			}
		}
		.page-blog{
			.postList{
				.postItem{
					height:270px;
				}
			}
		}
	}
}

@media only screen and (max-width : 540px){
	.listPostEventos{
		.content{
			.addthis_inline_share_toolbox{
				display:inherit;
				&:not(.category){
					padding-top:0;
				}
			}
		}
	}
	#bannerHome .navigation{
		right:40%;
	}
	#internas{
		article.single-eventos{
			.intro{
				overflow:visible;
				figure{
					width:100%;
					height:200px;
					float:none;
				}
				.content{
					width:100%;
					height:inherit;
					float:none;
					clear:both;

					.middle {.middleOff;}
				}
			}
		}
		aside{
			padding-left:0;
			&:before{
				display:none;
			}
		}
		.page-blog{
			.postList{
				.postItem{
					.piContent{
						.socialBar{
							margin:0 10px;
						}
					}
				}
			}
		}
	}
	#internas .page-blog .postList .postItem {height:inherit; clear:both; overflow:hidden;}
	#internas .page-blog .postList .postItem .col {width:100%; float:none; height:auto; display:block;}
	#internas .page-blog .postList .postItem .piContent {width:100%; float:none; clear:both; height:auto; overflow:hidden; padding:10px 15px 20px 15px; display:block;}
	#internas .page-blog .postList .postItem .piContent.full {padding:0 5px}
	#internas .page-blog .postList .postItem .piContent a.btn {padding:0 10px;}
	#internas .page-blog .postList .postItem .piContent .socialBar {float:right; margin:0}
	#internas .page-blog .postList .postItem.full .piContent.full .socialBar {margin:0; float:right;}
	#internas .page-blog-single article {padding:20px 10px;}
	#internas .page-blog-single article h1 {font-size:26px; line-height:26px; padding:8px 10px 8px 30px !important;}
	#internas .page-blog-single article .facebookComment .faceBox {padding:10px 5px}
	#internas .page-localizacao h3 {font-size:27px; line-height:29px;}
	#internas .espacos li .gallery .swiper-container {height:230px;}
}

@media only screen and (max-width : 482px){
	#internas{
		.espacos{
			ul{
				li{
					.content{
						.middle{
							top:65%;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width : 438px){
	#internas{
		article.single-eventos{
			.intro{
				.middle{
					margin-top:20px;
				}
			}
		}
	}
}

@media only screen and (max-width : 397px){
	#internas{
		article.single-eventos{
			.intro{
				.middle{
					margin-top:30px;
				}
			}
		}
	}
}

@media only screen and (max-width : 348px){
	#internas{
		article.single-eventos{
			.intro{
				.middle{
					margin-top:40px;
				}
			}
			.post{
				padding-top:90px;
			}
		}
		.page-blog{
			.postList{
				.postItem{
					height:410px;
				}
			}
		}
	}
}

@media only screen and (max-width : 334px){

}
