/* --------------------------------------------------------------
IMPORTS
----------------------------------------------------------------- */
@import 'bootstrap.css';
@import 'swiper.min.css';
/* --------------------------------------------------------------
RESET
----------------------------------------------------------------- */
@import 'clubedobatom.css';
* {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
  list-style-type: none;
  text-decoration: none;
  list-style-image: none;
  box-sizing: border-box;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
header,
section,
footer,
article,
figcaption,
hgroup,
figure,
details,
aside,
img,
nav,
time,
bloquote,
svg {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
audio:not([controls]) {
  display: none;
}
[hidden] {
  display: none;
}
object[type="application/gas-events-uni"] {
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  display: block;
  text-indent: -999999px;
}
input[type="search"],
input[type="submit"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border-radius: 0;
  -webkit-border-radius: 0;
}
/* --------------------------------------------------------------
GRID SYSTEM
----------------------------------------------------------------- */
.wrap {
  width: calc(100% - 16%);
  margin: 0 8%;
  position: relative;
  clear: both;
}
.dryWrap {
  width: 96%;
  margin: 0 28px;
  position: relative;
  clear: both;
}
/* --------------------------------------------------------------
DEFAULT STYLES
----------------------------------------------------------------- */
html,
body {
  height: 100%;
}
/* FORMATING CLASSES
---------------------------- */
.clear {
  clear: both;
}
.hide {
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  display: block;
  text-indent: -999999px;
}
hr {
  display: none;
}
.left {
  float: left;
}
.right {
  float: right;
}
.center {
  margin: 0 auto;
}
.middle {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.middleOff {
  top: inherit;
  transform: none;
}
.textRight {
  text-align: right;
}
.textLeft {
  text-align: left;
}
.textCenter {
  text-align: center;
}
img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}
img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}
img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
a img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}
a img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}
a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.padding60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.padding40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
span.bar {
  display: block;
  margin: 0 auto;
  margin-top: 15px;
  width: 36px;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}
/* SIZES */
.full_X {
  width: 100% !important;
  position: relative;
}
.full_Y {
  height: 100% !important;
  position: relative;
}
.fullSize {
  width: 100% !important;
  height: 100% !important;
  position: relative;
}
/* BACKGROUNDS */
.gradiente {
  position: relative;
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 30%, #000000 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 30%, #000000 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 30%, #000000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#4d000000', GradientType=0);
}
.gradienteDark {
  position: relative;
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.3) 30%, #000000 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3) 30%, #000000 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 30%, #000000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#4d000000', GradientType=0);
}
.bgdark {
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
}
.darkForm {
  background: rgba(0, 0, 0, 0.2);
}
/* COLORS */
/* FONTS */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USSwiPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjx4wWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh50XSwiPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'BebasNeue';
  src: url('fontes/BebasNeue.eot?#iefix') format('embedded-opentype'), url('fontes/BebasNeue.otf') format('opentype'), url('fontes/BebasNeue.woff') format('woff'), url('fontes/BebasNeue.ttf') format('truetype'), url('fontes/BebasNeue.svg#BebasNeue') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*
FIRA
LATO 300, Normal 400, Semibold 600, Black 900
*/
/* EFFECTS */
/* ALERTAS */
#lightbox {
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  opacity: 0;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
#lightbox div {
  margin: 0 auto;
  position: relative;
  width: 80%;
  max-width: 500px;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
#lightbox div p {
  padding: 20px 40px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
}
#lightbox a {
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 40px;
  width: 40px;
  line-height: 32px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border: 3px solid #fff;
  border-radius: 30px;
  font-size: 16px;
}
#lightbox .sucesso {
  background: #6eaa53;
}
#lightbox .sucesso a {
  background: #6eaa53;
}
#lightbox .erro {
  background: #f5506b;
}
#lightbox .erro a {
  background: #f5506b;
}
/* ALERTAS */
#popupBanner {
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  opacity: 0;
}
#popupBanner .wrapBanner {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1024px;
  max-width: 90%;
  border: 1px solid #ed1785;
}
#popupBanner .wrapBanner img {
  width: 100%;
}
#popupBanner .close {
  display: block;
  position: absolute;
  right: 0;
  top: -50px;
  color: #fff;
  font-size: 13px;
}
#popupBanner .close i {
  line-height: 40px;
  vertical-align: middle;
  font-size: 16px;
  margin-left: 10px;
}
/* ELEMENTOS */
.titulosecao {
  margin-bottom: 40px;
}
.titulosecao a {
  display: inline-block;
  padding-bottom: 7px;
  border-bottom: 5px solid #ed1785;
  font-family: 'BebasNeue', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
}
.btn {
  display: inline-block;
  height: 40px;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 36px;
  color: #fff;
  font-style: normal;
  padding: 0 20px;
  text-transform: uppercase;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
.btn.plus {
  padding: 0 40px;
}
.btn i {
  margin-right: 10px;
  font-size: 16px;
}
.btn.rosa.fill {
  background: #ed1785;
  line-height: 40px;
}
.btn.rosa.line {
  border: 2px solid #ed1785;
}
.btn.rosa.fill:hover {
  background: #ff4da9;
}
.btn.rosa.line:hover {
  border: 2px solid #ff4da9;
}
.btn.azul.fill {
  background: #0d0d3f;
  line-height: 40px;
}
.btn.azul.line {
  border: 2px solid #0d0d3f;
}
.btn.azul.fill:hover {
  background: #181850;
}
.btn.azul.line:hover {
  border: 2px solid #181850;
}
.btn.azulEscovado.fill {
  background: #15152e;
  line-height: 40px;
}
.btn.azulEscovado.line {
  border: 2px solid #15152e;
}
.btn.azulEscovado.fill:hover {
  background: #181850;
}
.btn.azulEscovado.line:hover {
  border: 2px solid #181850;
}
.btn.branco.fill {
  background: #fff;
}
.btn.branco.line {
  border: 2px solid #fff;
}
.btn.branco.fill:hover {
  color: #ed1785;
}
.btn.branco.line:hover {
  color: #ed1785;
}
@media (max-width: 680px) {
  .btn {
    height: 36px;
    line-height: 32px;
  }
  .btn.rosa.fill {
    line-height: 36px;
  }
  .btn.plus {
    padding: 0 30px;
  }
}
.pagenavi {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 40px;
  margin: 20px 0;
  text-align: center;
}
.pagenavi a,
.pagenavi span {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.1);
  margin: 0 1px;
  width: 40px;
  color: #888;
}
.pagenavi span.current {
  width: 40px;
  background: #ed1785;
  color: #fff;
  font-weight: 700;
}
/* --------------------------------------------------------------
THEME DEFAULT STYLES
----------------------------------------------------------------- */
body {
  background: url(../images/bg-body.png);
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
}
#master {
  position: relative;
  height: 100%;
  padding-top: 70px;
  z-index: 0;
  clear: both;
}
@media (max-width: 1024px) {
  #master {
    padding-top: 60px;
  }
}
@media (max-width: 540px) {
  #master {
    padding-top: 50px;
  }
}
/* --------------------------------------------------------------
HEADER
----------------------------------------------------------------- */
body > header {
  background-color: rgba(13, 13, 63, 0.9);
  position: fixed;
  width: 100%;
  z-index: 2;
  height: 70px;
}
body > header h1 {
  margin-top: 10px;
  float: left;
  height: 50px;
}
body > header h1 img {
  width: auto;
  height: 100%;
}
body > header h1 img.desktop {
  display: block;
}
body > header h1 img.mobile {
  display: none;
}
body > header .contentTop {
  float: right;
  height: 70px;
  position: relative;
  z-index: 1;
}
body > header .social {
  float: left;
  height: 40px;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
body > header .social li {
  display: inline-block;
  padding: 0 10px;
  height: 40px;
}
body > header .social li a {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
body > header .social li a i {
  font-size: 20px;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
body > header .social li:hover a i {
  color: #ff4da9;
}
body > header .eventos {
  float: left;
  padding: 0 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
body > header .linksRapidos {
  float: left;
  height: 70px;
  padding: 0 20px 0 10px;
  line-height: 17px;
}
body > header .linksRapidos li {
  display: inline-block;
  padding: 0 10px;
  height: 70px;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  border-bottom: 4px solid rgba(237, 23, 133, 0);
}
body > header .linksRapidos li a {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 70px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
body > header .linksRapidos li a i {
  font-size: 20px;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
body > header .linksRapidos li:hover {
  border-bottom: 4px solid #ff4da9;
}
body > header .busca {
  float: left;
  padding: 10px 15px 0 0;
  position: relative;
}
body > header .busca a {
  display: block;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.1);
  text-align: center;
}
body > header .busca a i {
  line-height: 47px;
  font-size: 18px;
  color: #fff;
}
body > header .busca form {
  position: absolute;
  display: none;
}
body > header .actions {
  position: relative;
  top: 10px;
  float: right;
  width: 50px;
  border-bottom: 4px solid #ed1785;
  height: 60px;
}
body > header .actions a {
  position: absolute;
  height: 50px;
  width: 50px;
  display: block;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
body > header .actions a i {
  color: #fff;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 28px;
  z-index: 0;
}
body > header .actions a.activated {
  z-index: 1;
}
body > header .actions a.open {
  background: #ed1785;
}
body > header .actions a.open:hover {
  background: #ff4da9;
}
body > header .actions a.close {
  background: #a7a7a7;
}
body > header .actions a.close:hover {
  background: #b8b8b8;
}
body > header nav {
  position: fixed;
  top: 70px;
  width: 100%;
  background: #0d0d3f;
  border-radius: 0 0 5px 5px;
  text-align: right;
  height: 0;
  overflow: hidden;
}
body > header nav .container {
  padding-top: 40px;
  padding-bottom: 40px;
}
body > header nav ul:not(.secondary-menu) {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  padding-right: 30px;
}
body > header nav ul:not(.secondary-menu) > li {
  min-height: 60px;
}
body > header nav ul:not(.secondary-menu) > li > a {
  font-family: 'BebasNeue', sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 60px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
}
body > header nav ul:not(.secondary-menu) > li > a:before {
  display: block;
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0;
  bottom: 5px;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  content: "";
}
body > header nav ul:not(.secondary-menu) > li > a:hover:before {
  background: rgba(255, 255, 255, 0.2);
}
body > header nav ul:not(.secondary-menu) > li.current-menu-item > a:before {
  background: #ed1785;
}
body > header nav ul.secondary-menu li a {
  font-family: 'BebasNeue', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  height: 40px;
  display: block;
  position: relative;
  padding-right: 37px;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
body > header nav ul.secondary-menu li a:after {
  content: " ";
  display: block;
  width: 22px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-51%);
  text-align: center;
}
body > header nav ul.secondary-menu li.happyhour a:after {
  font-family: clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\e804";
  color: #ed1785;
  font-size: 28px;
}
body > header nav ul.secondary-menu li.private a:after {
  font-family: clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\e800";
  color: #ed1785;
  font-size: 28px;
}
body > header nav ul.secondary-menu li.sexydelivery a:after {
  font-family: clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\e802";
  color: #ed1785;
  font-size: 26px;
}
body > header nav ul.secondary-menu li.despedidasolteira a:after {
  font-family: clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\e803";
  color: #ed1785;
  font-size: 26px;
}
body > header nav ul.secondary-menu li.ensaiosensual a:after {
  font-family: clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\e805";
  color: #ed1785;
  font-size: 26px;
}
body > header nav ul.secondary-menu li.cursospalestras a:after {
  font-family: clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\e801";
  color: #ed1785;
  font-size: 26px;
}
body > header nav .info {
  clear: both;
  text-align: right;
  padding-top: 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  padding-right: 30px;
}
body > header nav .info a {
  display: inline-block;
  margin: 0 0 0 5px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
body > header nav .info a.mail {
  font-weight: 600;
}
body > header nav .info a i {
  display: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 26px;
  margin-left: 5px;
}
body > header nav .social {
  float: none;
  border: 0;
  text-align: right;
  padding: 30px 0 20px 0;
  position: relative;
  right: -5px;
  display: none;
}
body > header nav .social i {
  width: 30px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 24px !important;
  text-align: center;
}
@media (max-width: 1024px) {
  body > header nav ul:not(.submenu) > li > a {
    font-size: 26px;
  }
  body > header nav .menu-terciario-container ul:not(.submenu) {
    border: 0;
  }
  body > header nav ul.secondary-menu li {
    min-height: inherit;
    padding: 10px 0;
  }
  body > header nav ul.secondary-menu li a {
    height: auto;
    line-height: 26px;
  }
}
@media (max-width: 992px) {
  body > header .linksRapidos {
    display: none;
  }
  body > header nav .container {
    padding-bottom: 40px;
  }
  body > header nav ul:not(.submenu) {
    padding-right: 15px;
  }
  body > header nav ul:not(.submenu) > li {
    min-height: 52px;
  }
  body > header nav ul:not(.submenu) > li > a {
    font-size: 22px;
    line-height: 52px;
  }
  body > header nav ul.submenu li a {
    font-size: 14px;
    letter-spacing: 1px;
    padding-right: 27px;
    height: 36px;
    line-height: 36px;
  }
  body > header nav ul.submenu li a:after {
    font-size: 20px !important;
    width: 16px;
    right: 0;
  }
  body > header nav ul.secondary-menu li {
    min-height: inherit;
    padding: 10px 0;
  }
  body > header nav ul.secondary-menu li a {
    height: auto;
    line-height: 22px;
  }
  body > header nav .info {
    padding-right: 15px;
    padding-top: 15px;
    border: 0;
  }
  body > header nav .info a span {
    display: none;
  }
  body > header nav .info a i {
    display: inline-block;
  }
}
@media (max-width: 740px) {
  body > header {
    height: 60px;
  }
  body > header h1 {
    height: 40px;
  }
  body > header h1 img.desktop {
    display: none;
  }
  body > header h1 img.mobile {
    display: block;
  }
  body > header .contentTop {
    height: 60px;
  }
  body > header .contentTop .social {
    display: none;
  }
  body > header .busca {
    padding-top: 5px;
  }
  body > header .actions {
    top: 5px;
    height: 58px;
  }
  body > header nav {
    top: 60px;
    overflow: auto;
    height: calc(100% - 60px);
    width: 320px;
    right: -320px;
  }
  body > header nav .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  body > header nav ul:not(.submenu) {
    padding-right: 10px;
    border: 0;
  }
  body > header nav ul:not(.submenu) > li > a {
    font-size: 28px;
  }
  body > header nav ul.submenu {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 15px;
    padding-bottom: 10px;
    margin: 10px 0;
  }
  body > header nav ul.submenu li a {
    font-size: 16px;
    letter-spacing: 1px;
    padding-right: 27px;
    height: inherit;
    line-height: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  body > header nav ul.submenu li a:after {
    font-size: 20px !important;
    width: 16px;
    right: 0;
  }
  body > header nav ul.secondary-menu li {
    min-height: inherit;
    padding: 10px 0;
  }
  body > header nav ul.secondary-menu li a {
    height: auto;
    line-height: 22px;
  }
  body > header nav .social {
    display: block;
  }
}
@media (max-width: 540px) {
  body > header {
    height: 50px;
  }
  body > header h1 {
    height: 30px;
  }
  body > header .contentTop {
    height: 50px;
  }
  body > header .busca {
    display: none;
  }
  body > header .eventos {
    padding: 0 20px 0 0;
    border: 0;
  }
  body > header .eventos .btn.rosa.line {
    padding: 0 15px;
    height: 36px;
    line-height: 32px;
  }
  body > header .actions {
    height: 48px;
    width: 40px;
  }
  body > header .actions a {
    width: 40px;
    height: 40px;
  }
  body > header .actions a i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
  }
  body > header nav {
    top: 50px;
    height: calc(100% - 50px);
  }
  body > header nav ul:not(.submenu) {
    padding-right: 5px;
    border: 0;
  }
  body > header nav ul:not(.submenu) > li {
    min-height: 44px;
  }
  body > header nav ul:not(.submenu) > li > a {
    font-size: 20px;
    line-height: 44px;
  }
  body > header nav ul.submenu li a {
    font-size: 14px;
    letter-spacing: 1.5px;
  }
  body > header nav ul.secondary-menu li {
    min-height: inherit;
    padding: 10px 0;
  }
  body > header nav ul.secondary-menu li a {
    height: auto;
    line-height: 22px;
  }
}
@media (max-width: 320px) {
  body > header .eventos {
    padding: 0 10px 0 0;
    border: 0;
  }
  body > header .eventos .btn.rosa.line {
    padding: 0 10px;
    font-size: 11px;
  }
}
/* --------------------------------------------------------------
BANNERS
----------------------------------------------------------------- */
/* BANNER HOME
----------------------------- */
#bannerHome {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#bannerHome .swiper-container {
  height: 100%;
  width: 100%;
  z-index: 0;
}
#bannerHome .swiper-slide {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
}
#bannerHome .container,
#bannerHome .row {
  height: 100%;
  position: relative;
}
#bannerHome .legenda {
  position: absolute;
  bottom: 60px;
  border-left: 8px solid #ed1785;
  padding: 2px 0 2px 25px;
  margin-left: 15px;
}
#bannerHome .legenda h3 {
  font-family: 'BebasNeue', sans-serif;
  font-size: 66px;
  font-weight: 400;
  line-height: 68px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 15px;
}
#bannerHome .legenda p {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 24px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding: 17px 0 20px 0;
}
#bannerHome .legenda .buttons {
  padding-top: 60px;
  clear: both;
  text-align: center;
}
#bannerHome .creditos {
  position: absolute;
  right: 110px;
  bottom: 70px;
  font-size: 13px;
  color: #a7a7a7;
}
#bannerHome .navigation {
  position: absolute;
  z-index: 1;
  bottom: 70px;
  right: 50%;
  margin-right: -571px;
  width: 90px;
  text-align: right;
}
#bannerHome .navigation span {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
  margin: 0 2px;
  opacity: 1;
}
#bannerHome .navigation span.swiper-pagination-bullet-active {
  background: #fff;
}
@media (max-width: 1024px) {
  #bannerHome .legenda {
    bottom: 30px;
  }
  #bannerHome .legenda h3 {
    font-size: 52px;
    line-height: 52px;
    padding-bottom: 10px;
  }
  #bannerHome .legenda p {
    font-size: 18px;
    line-height: 20px;
    padding: 12px 0 20px 0;
  }
  #bannerHome .creditos {
    bottom: 40px;
    right: 140px;
  }
  #bannerHome .navigation {
    margin-right: 0;
    right: 30px;
    bottom: 40px;
  }
}
@media (max-width: 992px) {
  #bannerHome {
    height: 60%;
  }
  #bannerHome .legenda h3 {
    font-size: 46px;
    line-height: 46px;
    padding-bottom: 8px;
  }
  #bannerHome .legenda p {
    font-size: 16px;
    line-height: 18px;
    padding: 10px 0 20px 0;
  }
  #bannerHome .creditos {
    bottom: 55px;
    right: 30px;
  }
  #bannerHome .navigation {
    bottom: 30px;
  }
}
@media (max-width: 740px) {
  #bannerHome {
    height: 100%;
  }
  #bannerHome .legenda {
    max-width: 70%;
  }
  #bannerHome .legenda h3 {
    font-size: 38px;
    line-height: 38px;
    padding-bottom: 8px;
  }
  #bannerHome .legenda p {
    padding: 10px 0 20px 0;
  }
}
@media (max-width: 540px) {
  #bannerHome {
    height: 60%;
  }
  #bannerHome .legenda {
    max-width: inherit;
  }
  #bannerHome .legenda h3 {
    font-size: 30px;
    line-height: 30px;
  }
  #bannerHome .legenda p {
    font-size: 14px;
    line-height: 16px;
    padding: 8px 0 20px 0;
  }
}
@media (max-width: 320px) {
  #bannerHome .legenda {
    padding: 2px 0 2px 15px;
    margin-left: 0;
    bottom: 20px;
  }
  #bannerHome .legenda h3 {
    border: 0;
    padding-bottom: 15px;
  }
  #bannerHome .legenda p {
    display: none;
  }
  #bannerHome .creditos {
    bottom: 40px;
    right: 15px;
  }
  #bannerHome .navigation {
    bottom: 18px;
    right: 15px;
  }
}
#bannerInternas {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
}
#bannerInternas .container,
#bannerInternas .row {
  position: relative;
  height: 100%;
}
#bannerInternas .legenda {
  position: absolute;
  bottom: 50px;
}
#bannerInternas .legenda h2 {
  font-family: 'BebasNeue', sans-serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 46px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  border-left: 8px solid #ed1785;
  padding: 5px 0 0 25px;
}
#bannerInternas .legenda h2 a {
  color: #fff;
}
#bannerInternas .legendaServicos h2 {
  font-family: 'BebasNeue', sans-serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 46px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  border-left: 0;
  padding: 5px 0 0 25px;
  padding-left: 0;
}
#bannerInternas .legendaServicos h2 i {
  color: #ed1785;
}
@media (max-width: 1024px) {
  #bannerInternas {
    height: 400px;
  }
}
@media (max-width: 992px) {
  #bannerInternas {
    height: 400px;
  }
  #bannerInternas .legenda {
    bottom: 40px;
  }
}
@media (max-width: 740px) {
  #bannerInternas {
    height: 100%;
  }
  #bannerInternas .legenda {
    bottom: 30px;
  }
  #bannerInternas .legenda h2 {
    font-size: 40px;
    line-height: 36px;
    padding: 5px 0 0 20px;
  }
}
@media (max-width: 540px) {
  #bannerInternas {
    height: 280px;
  }
  #bannerInternas .legenda {
    bottom: 30px;
  }
  #bannerInternas .legenda h2 {
    font-size: 30px;
    line-height: 26px;
    padding: 5px 0 0 15px;
  }
}
@media (max-width: 320px) {
  #bannerInternas {
    height: 240px;
  }
  #bannerInternas .legenda h2 {
    font-size: 26px;
    line-height: 22px;
    padding: 5px 0 0 15px;
  }
}
/* --------------------------------------------------------------
HOME
----------------------------------------------------------------- */
/* INTRO
------------------------------------- */
#intro {
  padding: 60px 0;
  background-color: rgba(13, 13, 63, 0.5);
}
#intro h3 {
  font-family: 'Lato', sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 38px;
  color: #d8d7d7;
  font-style: normal;
  text-transform: none;
  border-right: 1px solid #ed1785;
  padding: 3px 85px 3px 0;
  margin-right: 30px;
}
#intro p {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #a7a7a7;
  font-style: italic;
  text-transform: none;
}
@media (max-width: 1024px) {
  #intro {
    padding: 40px 0;
  }
}
@media (max-width: 992px) {
  #intro h3 {
    border: 0;
    padding-right: 40px;
    padding-bottom: 20px;
    margin: 0;
  }
}
@media (max-width: 540px) {
  #intro h3 {
    font-size: 30px;
    line-height: 32px;
  }
}
@media (max-width: 320px) {
  #intro h3 {
    font-size: 26px;
    line-height: 28px;
  }
}
/* GALLERY
------------------------------------- */
#gallery {
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.6);
  position: relative;
}
#gallery .swiper-container {
  width: 100%;
  z-index: 0;
  height: 500px;
}
#gallery .swiper-slide {
  width: 980px;
  background: rgba(0, 0, 0, 0.5);
}
#gallery .swiper-slide a {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
#gallery .swiper-slide.swiper-slide-active a {
  -webkit-filter: none;
  filter: none;
}
#gallery .navigation {
  position: relative;
  z-index: 1;
  width: 980px;
  margin: 0 auto;
}
#gallery .navigation span {
  display: block;
  width: 77px;
  position: absolute;
  text-align: center;
  background: rgba(255, 255, 255, 0.15);
  color: #676767;
  cursor: pointer;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
#gallery .navigation span i {
  font-size: 60px;
  height: 500px;
  line-height: 480px;
}
#gallery .navigation span:hover {
  background: #242225;
  color: #fff;
}
#gallery .navigation span.arrow-left {
  left: -78px;
}
#gallery .navigation span.arrow-right {
  right: -78px;
}
@media (max-width: 1024px) {
  #gallery .swiper-container {
    height: 400px;
  }
  #gallery .swiper-slide {
    width: calc(100% - 160px);
  }
  #gallery .navigation {
    width: calc(100% - 164px);
  }
  #gallery .navigation span.arrow-left {
    left: -72px;
  }
  #gallery .navigation span.arrow-right {
    right: -72px;
  }
  #gallery .navigation span i {
    font-size: 50px;
    height: 400px;
    line-height: 380px;
  }
}
@media (max-width: 992px) {
  #gallery .swiper-slide {
    width: calc(100% - 134px);
  }
  #gallery .navigation {
    width: calc(100% - 120px);
  }
  #gallery .navigation span {
    width: 60px;
  }
  #gallery .navigation span.arrow-left {
    left: -54px;
  }
  #gallery .navigation span.arrow-right {
    right: -54px;
  }
}
@media (max-width: 740px) {
  #gallery .swiper-container {
    height: 360px;
  }
  #gallery .swiper-slide {
    width: calc(100% - 120px);
  }
  #gallery .navigation {
    width: calc(100% - 120px);
  }
  #gallery .navigation span.arrow-left {
    left: -53px;
  }
  #gallery .navigation span.arrow-right {
    right: -53px;
  }
  #gallery .navigation span i {
    font-size: 40px;
    height: 360px;
    line-height: 340px;
  }
}
@media (max-width: 540px) {
  #gallery .swiper-container {
    height: 260px;
  }
  #gallery .swiper-slide {
    width: calc(100% - 80px);
  }
  #gallery .navigation {
    width: calc(100% - 80px);
  }
  #gallery .navigation span {
    width: 40px;
  }
  #gallery .navigation span.arrow-left {
    left: -40px;
  }
  #gallery .navigation span.arrow-right {
    right: -40px;
  }
  #gallery .navigation span i {
    font-size: 30px;
    height: 260px;
    line-height: 240px;
  }
}
@media (max-width: 320px) {
  #gallery .swiper-container {
    height: 220px;
  }
  #gallery .navigation span i {
    font-size: 30px;
    height: 220px;
    line-height: 200px;
  }
}
/* SERVIÇOS
------------------------------------- */
#servicosHome {
  padding: 60px 0 30px 0;
  background-color: rgba(13, 13, 63, 0.5);
}
#servicosHome ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  clear: both;
}
#servicosHome li {
  margin-bottom: 30px;
  position: relative;
}
#servicosHome li a {
  display: block;
  background: #0a0a20;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 25px 25px 12px 80px;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  border: 2px solid rgba(255, 255, 255, 0.1);
}
#servicosHome li a:hover {
  border: 2px solid #ffffff;
}
#servicosHome li a h3 {
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  position: relative;
}
#servicosHome li a p {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding-top: 15px;
}
#servicosHome li a:before {
  content: " ";
  display: block;
  width: 60px;
  position: absolute;
  left: 10px;
  top: 23px;
  text-align: center;
}
#servicosHome li a.happyhour:before {
  font-family: clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\e804";
  color: #ed1785;
  font-size: 42px;
}
#servicosHome li a.private:before {
  font-family: clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\e800";
  color: #ed1785;
  font-size: 42px;
}
#servicosHome li a.sexydelivery:before {
  font-family: clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\e802";
  color: #ed1785;
  font-size: 42px;
}
#servicosHome li a.despedidasolteira:before {
  font-family: clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\e803";
  color: #ed1785;
  font-size: 42px;
}
#servicosHome li a.ensaiosensual:before {
  font-family: clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\e805";
  color: #ed1785;
  font-size: 42px;
}
#servicosHome li a.cursospalestras:before {
  font-family: clubedobatom;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\e801";
  color: #ed1785;
  font-size: 42px;
}
@media screen and (max-width: 1024px) {
  #servicosHome {
    padding: 40px 0 10px 0;
  }
  #servicosHome li a {
    padding: 12px 25px 0 70px;
  }
  #servicosHome li a h3 {
    font-size: 15px;
  }
  #servicosHome li a p {
    padding-top: 6px;
    height: 84px;
    line-height: 17px;
  }
  #servicosHome li a:before {
    width: 50px;
    left: 5px;
    font-size: 38px !important;
  }
}
@media screen and (max-width: 540px) {
  #servicosHome ul {
    display: block;
  }
  #servicosHome li {
    margin-bottom: 15px !important;
  }
  #servicosHome li a {
    height: inherit;
    padding-bottom: 12px;
  }
  #servicosHome li a p {
    height: inherit;
    line-height: 20px;
  }
}
/* EVENTOS
------------------------------------- */
#eventosHome {
  padding: 60px 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
}
@media (max-width: 1024px) {
  #eventosHome {
    padding: 40px 0;
  }
}
@media (max-width: 740px) {
  #eventosHome {
    padding: 20px 0;
  }
}
@media (max-width: 540px) {
  #eventosHome.interna {
    padding: 20px 0 0 0;
  }
}
.listPostEventos li {
  margin-bottom: 30px;
}
.listPostEventos img {
  width: 100%;
}
.listPostEventos .content {
  padding: 20px;
  background: #0a0a20;
}
.listPostEventos .content h3 {
  font-family: 'BebasNeue', sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 38px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding-bottom: 15px;
}
.listPostEventos .content h3 a {
  color: #fff;
}
.listPostEventos .content p {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding: 20px 0;
}
.listPostEventos .content time {
  display: block;
  color: #fff;
}
.listPostEventos .content time i {
  font-size: 20px;
  margin-right: 5px;
}
.listPostEventos .content .addthis_inline_share_toolbox {
  float: right;
  padding-top: 3px;
}
.listPostEventos.category {
  padding-top: 20px;
}
.listPostEventos.category li {
  clear: both;
  overflow: hidden;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.listPostEventos.category li:last-child {
  margin-bottom: 30px;
  padding-bottom: 0;
  border: 0;
}
.listPostEventos.category .content {
  background: none;
  padding-top: 0;
}
@media (max-width: 1024px) {
  .listPostEventos .content h3 {
    font-size: 28px;
    line-height: 30px;
  }
  .listPostEventos .content p {
    font-size: 14px;
    line-height: 20px;
    padding: 15px 0 20px 0;
  }
  .listPostEventos .content time i {
    font-size: 16px;
    margin-right: 5px;
  }
  .listPostEventos:not(.category) .content .addthis_inline_share_toolbox {
    float: none;
    clear: both;
    padding-top: 20px;
  }
}
@media (max-width: 992px) {
  .listPostEventos.category .content {
    padding-top: 20px;
  }
}
@media (max-width: 540px) {
  .listPostEventos .content h3 {
    font-size: 24px;
    line-height: 26px;
  }
  .listPostEventos .content a.btn {
    display: block;
    clear: both;
    margin-bottom: 5px;
    float: left;
  }
  .listPostEventos .content .addthis_inline_share_toolbox {
    float: none;
    clear: both;
    padding-top: 20px;
  }
  .listPostEventos.category li {
    padding-bottom: 15px;
  }
  .listPostEventos.category .content .addthis_inline_share_toolbox {
    float: none;
    clear: both;
    padding-top: 20px;
  }
}
/* --------------------------------------------------------------
INTERNAS
----------------------------------------------------------------- */
#internas:not(.light) {
  background: url(../images/bg-internas.png);
}
#internas article.single-eventos {
  padding: 80px 0;
  overflow: hidden;
}
#internas article.single-eventos h1 {
  font-family: 'Lato', sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 38px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
}
#internas article.single-eventos .evento {
  padding-right: 30px;
}
#internas article.single-eventos .intro {
  overflow: hidden;
  margin-bottom: 30px;
}
#internas article.single-eventos .intro figure {
  width: 250px;
  height: 250px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
  margin-right: 40px;
  float: left;
}
#internas article.single-eventos .intro figure img {
  display: none;
}
#internas article.single-eventos .intro .content {
  width: calc(100% - 290px);
  height: 250px;
  float: left;
}
#internas article.single-eventos .intro time {
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
#internas article.single-eventos .intro p {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 26px;
  color: #b8b8b8;
  font-style: italic;
  text-transform: none;
  padding: 20px 0;
}
#internas article.single-eventos .post {
  clear: both;
  padding-bottom: 30px;
}
#internas article.single-eventos .post p {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #cfcfcf;
  font-style: normal;
  text-transform: none;
  padding: 0 0 0 30px;
  margin-bottom: 30px;
  border-left: 5px solid #ed1785;
}
#internas article.single-eventos .post p.noBorder {
  padding: 0;
  border: 0;
}
#internas article.single-eventos .post p a {
  color: #ed1785;
  text-decoration: underline;
}
#internas article.single-eventos .post img {
  max-width: 100% !important;
  height: auto !important;
}
#internas article.single-eventos .post img {
  max-width: 100% !important;
  margin-bottom: 30px;
}
#internas article.single-eventos .post .addthis_inline_share_toolbox {
  clear: both;
  padding-top: 20px;
}
#internas article.single-eventos .post a.btn {
  margin: 30px 20px 0 0;
}
#internas .page-blog {
  padding: 80px 0;
  overflow: hidden;
}
#internas .page-blog .postList .postItem {
  width: auto;
  height: 240px;
  background: #090909;
  margin: 0 0 40px 0;
}
#internas .page-blog .postList .postItem.full {
  height: auto;
  overflow: hidden;
  padding: 10px 10px 30px 10px;
}
#internas .page-blog .postList .postItem .col {
  width: 35%;
  height: 100%;
  float: left;
}
#internas .page-blog .postList .postItem .col img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  float: left;
}
#internas .page-blog .postList .postItem .piContent {
  width: 65%;
  height: 100%;
  float: left;
  padding: 20px;
}
#internas .page-blog .postList .postItem .piContent.full {
  width: 100%;
  float: none;
}
#internas .page-blog .postList .postItem .piContent h2 a {
  font-family: 'BebasNeue', sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  display: block;
  float: none;
}
#internas .page-blog .postList .postItem .piContent p {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding: 25px 0;
}
#internas .page-blog .postList .postItem .piContent a {
  font-size: 16px;
  float: left;
}
#internas .page-blog .postList .postItem .piContent .socialBar {
  float: left;
  margin: 0 20px;
}
#internas .page-blog .postList .postItem .piContent .socialBar .addthis_inline_share_toolbox {
  position: relative;
  top: 4px;
}
#internas .page-blog .postList .postItem .piContent .socialBar .sbItem {
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "";
  color: #fff;
  font-size: 20px;
  background: #b8b8b8;
  padding: 9px;
  margin: 0 0 0 15px;
}
#internas .page-blog .postList .postItem .piContent .socialBar .sbItem:hover {
  background: #ed1785;
}
#internas .page-blog .postList .postItem .piContent .socialBar .facebook {
  background: #3b579d;
  padding: 9px 14px;
}
#internas .page-blog .postList .postItem .piContent .socialBar .twitter {
  background: #1da1f2;
  padding: 9px 11px;
}
#internas .page-blog .postList .postItem .piContent .socialBar .plus {
  background: #ff6751;
  padding: 9px 11px;
}
#internas .page-blog .postList .postItem .picFull {
  width: 100%;
}
#internas .page-blog .postList .paginacao .wp-pagenavi {
  clear: both;
  padding-top: 40px;
}
#internas .page-blog .postList .paginacao .wp-pagenavi span,
#internas .page-blog .postList .paginacao .wp-pagenavi a {
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  padding: 10px 15px;
  background: #282828;
  color: #fff;
  margin: 0 1px;
}
#internas .page-blog .postList .paginacao .wp-pagenavi span:hover,
#internas .page-blog .postList .paginacao .wp-pagenavi a:hover,
#internas .page-blog .postList .paginacao .wp-pagenavi span.current,
#internas .page-blog .postList .paginacao .wp-pagenavi a.current {
  background: #ff4da9;
}
#internas .page-blog .postList .paginacao .active {
  background: #ed1785;
}
#internas .page-blog h1 {
  font-family: 'Lato', sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 38px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
}
#internas .page-blog-single {
  padding: 80px 0;
  overflow: hidden;
}
#internas .page-blog-single img {
  width: 100%;
}
#internas .page-blog-single article {
  width: 100%;
  height: 100%;
  background: #090909;
  padding: 20px 25px;
}
#internas .page-blog-single article h1 {
  font-family: 'BebasNeue', sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 36px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  display: inline-block;
  position: relative;
  left: -30px;
  padding: 8px 20px 8px 25px !important;
  margin: 20px 0;
  padding-left: 10px;
  background: #ed1785;
}
#internas .page-blog-single article h1 span {
  position: relative;
  top: 2px;
  display: inline-block;
}
#internas .page-blog-single article time {
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 40px;
}
#internas .page-blog-single article p {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding: 0 0 20px 0;
}
#internas .page-blog-single article img {
  max-width: 100% !important;
  height: auto !important;
}
#internas .page-blog-single article ul li,
#internas .page-blog-single article ol li {
  position: relative;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  font-style: italic;
  text-transform: none;
  padding: 8px 0;
  padding-left: 12px;
}
#internas .page-blog-single article ul li:before,
#internas .page-blog-single article ol li:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background: #ed1785;
  border-radius: 10px;
  left: 0;
  top: 17px;
}
#internas .page-blog-single article strong {
  color: #fff;
  font-weight: 700;
}
#internas .page-blog-single article a {
  color: #ed1785;
}
#internas .page-blog-single article .socialBar {
  margin: 40px 0;
  padding: 0 0 40px 0;
}
#internas .page-blog-single article .socialBar .addthis_inline_share_toolbox {
  position: relative;
  top: 4px;
}
#internas .page-blog-single article .socialBar .sbItem {
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "";
  color: #fff;
  font-size: 20px;
  background: #b8b8b8;
  padding: 9px;
  margin: 0 15px 0 0;
}
#internas .page-blog-single article .socialBar .sbItem:hover {
  background: #ed1785;
}
#internas .page-blog-single article .socialBar .facebook {
  background: #3b579d;
  padding: 9px 14px;
}
#internas .page-blog-single article .socialBar .twitter {
  background: #1da1f2;
  padding: 9px 11px;
}
#internas .page-blog-single article .socialBar .plus {
  background: #ff6751;
  padding: 9px 11px;
}
#internas .page-blog-single article .facebookComment h3 {
  font-family: 'BebasNeue', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding: 0 0 20px 0;
}
#internas .page-blog-single article .facebookComment .faceBox {
  padding: 10px 20px;
  background: #111111;
}
#internas .page-contato {
  padding: 80px 0;
  overflow: hidden;
}
#internas .page-contato h3 {
  font-family: 'Lato', sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 22px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding-bottom: 20px;
  margin: 10px 0 0 0;
}
#internas .page-contato p {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding: 20px 0;
}
#internas .page-contato p.contatoTel {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 18px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding: 10px 0;
}
#internas .page-contato p.contatoEmail {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 18px;
  color: #ed1785;
  font-style: normal;
  text-transform: none;
  padding: 10px 0;
  padding-bottom: 40px;
}
#internas .page-localizacao {
  padding: 80px 0;
  overflow: hidden;
}
#internas .page-localizacao h3 {
  font-family: 'Lato', sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 36px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding-bottom: 20px;
  margin: 10px 0 0 0;
}
#internas .page-localizacao p {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding: 20px 0;
}
#internas .page-localizacao .traceRota form {
  padding-bottom: 30px;
}
#internas .page-localizacao .traceRota form fieldset {
  position: relative;
}
#internas .page-localizacao .traceRota form p {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 22px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
#internas .page-localizacao .traceRota form span {
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.4);
  font-style: normal;
  text-transform: none;
  padding-top: 10px;
}
#internas .page-localizacao .traceRota form input {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  width: 100%;
  padding: 0 60px 0 15px;
  height: 50px;
  background: #343434;
}
#internas .page-localizacao .traceRota form button {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 50px;
  cursor: pointer;
  background: transparent;
}
#internas .page-localizacao .traceRota form button i {
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
#internas .page-localizacao .traceRota form button:hover i {
  color: #ed1785;
}
#internas .page-localizacao .traceRota #map {
  width: 100%;
  height: 450px;
}
#internas .page-localizacao .traceRota #trajeto-texto {
  padding-top: 20px;
}
#internas .page-localizacao .traceRota #trajeto-texto .adp,
#internas .page-localizacao .traceRota #trajeto-texto .adp table {
  color: #b8b8b8;
}
#internas .page-localizacao .traceRota #trajeto-texto .adp-placemark {
  background: #343434;
  color: #fff !important;
  border: 0;
  font-size: 16px;
  font-weight: 400;
}
#internas .page-localizacao .traceRota #trajeto-texto img.adp-marker2 {
  width: 18px;
  height: 50px;
  margin: 0 15px;
}
#internas .page-quemsomos {
  padding: 80px 0;
  overflow: hidden;
}
#internas .page-quemsomos h3 {
  font-family: 'Lato', sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 36px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding-bottom: 20px;
  margin: 10px 0 0 0;
}
#internas .page-quemsomos p {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding: 20px 0;
}
#internas .page-single-servicos {
  padding: 80px 0;
  overflow: hidden;
}
#internas .page-single-servicos h2 {
  font-family: 'Lato', sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 36px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
  margin: 10px 0 0 0;
}
#internas .page-single-servicos h3 {
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  font-weight: 300;
  line-height: 28px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding-bottom: 20px;
  text-align: center;
}
#internas .page-single-servicos p {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #b8b8b8;
  font-style: normal;
  text-transform: none;
  padding: 0 0 20px 0;
}
#internas .page-single-servicos p a {
  color: #ed1785;
}
#internas .page-single-servicos .content ul li,
#internas .page-single-servicos .content ol li {
  position: relative;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  font-style: italic;
  text-transform: none;
  padding: 8px 0;
  padding-left: 12px;
}
#internas .page-single-servicos .content ul li:before,
#internas .page-single-servicos .content ol li:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background: #ed1785;
  border-radius: 10px;
  left: 0;
  top: 17px;
}
#internas .page-single-servicos .content ul li h3,
#internas .page-single-servicos .content ol li h3 {
  font-family: 'BebasNeue', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  text-align: left;
}
#internas .page-single-servicos .depoimentos-parceiros {
  padding: 80px 0;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList {
  overflow: hidden;
  padding: 40px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  clear: both;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList:first-child {
  border: 0;
  padding-top: 0;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList:before {
  content: none;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList ul {
  padding-bottom: 15px;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList figure .bgIMG {
  position: relative;
  width: 100%;
  height: 200px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList figure .bgIMG.contain {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
  background-position: center;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList figure img {
  display: none;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList .content {
  position: relative;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList i {
  display: block;
  font-size: 24px;
  color: #ed1785;
  position: relative;
  margin-bottom: 5px;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList p {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #b8b8b8;
  font-style: italic;
  text-transform: none;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList a {
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList .footer {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #535353;
  font-style: normal;
  text-transform: none;
}
#internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList .footer strong {
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
@media (max-width: 1024px) {
  #internas .page-single-servicos .depoimentos-parceiros {
    padding: 40px 0;
  }
}
@media (max-width: 740px) {
  #internas .page-single-servicos .depoimentos-parceiros {
    padding: 0;
  }
}
@media (max-width: 540px) {
  #internas .page-single-servicos .depoimentos-parceiros ul.listPosts li.firstList figure {
    margin-bottom: 20px;
  }
}
#internas .ensaios {
  overflow: hidden;
  clear: both;
  margin-top: 60px;
}
#internas .ensaios ul {
  padding: 0 10px;
}
#internas .ensaios li {
  padding: 5px;
}
#internas .ensaios li a {
  display: block;
  position: relative;
}
#internas .ensaios li span {
  display: none;
}
@media (min-width: 1280px) {
  #internas .ensaios li span {
    position: absolute;
    z-index: 1;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;
    background: rgba(237, 23, 133, 0.6);
    text-align: center;
    display: block;
    opacity: 0;
    -webkit-transition: all 400ms ease;
    /* Safari */
    transition: all 400ms ease;
  }
  #internas .ensaios li span i {
    color: #fff;
    font-size: 24px;
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  #internas .ensaios li a:hover span {
    opacity: 1;
  }
}
#internas .ensaios li img {
  width: 100%;
  position: relative;
  z-index: 0;
}
#internas .espacos {
  overflow: hidden;
  clear: both;
  margin-top: 40px;
}
#internas .espacos h3 {
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding-top: 40px;
  padding-bottom: 30px;
  text-align: left;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
#internas .espacos ul {
  padding-top: 20px;
}
#internas .espacos li {
  clear: both;
  overflow: hidden;
  padding: 40px 0;
  text-align: right;
}
#internas .espacos li .gallery {
  position: relative;
  float: right;
}
#internas .espacos li .gallery .swiper-container {
  height: 370px;
  width: 100%;
  position: relative;
  z-index: 0;
}
#internas .espacos li .gallery .swiper-container .swiper-slide {
  height: 100%;
}
#internas .espacos li .gallery .swiper-container .swiper-slide a {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
  position: relative;
}
#internas .espacos li .gallery .swiper-container .legend {
  position: absolute;
  padding: 20px;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
#internas .espacos li .gallery .navigation span {
  position: absolute;
  top: 0;
  width: 60px;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
}
#internas .espacos li .gallery .navigation span:hover {
  background: rgba(255, 255, 255, 0.2);
}
#internas .espacos li .gallery .navigation span i {
  color: #fff;
  text-align: center;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 50px;
}
#internas .espacos li .gallery .navigation span.arrow-left {
  left: 15px;
}
#internas .espacos li .gallery .navigation span.arrow-right {
  right: 15px;
}
#internas .espacos li .content {
  height: 370px;
  float: left;
}
#internas .espacos li .content h4 {
  display: inline-block;
  margin-bottom: 30px;
  padding-bottom: 7px;
  border-bottom: 5px solid #ed1785;
  font-family: 'BebasNeue', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
}
#internas .espacos li .content p {
  padding-bottom: 20px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
#internas .espacos li .content p a {
  color: #ed1785;
  text-decoration: underline;
}
#internas .espacos li:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.2);
  text-align: left;
}
#internas .espacos li:nth-child(odd) .gallery {
  float: left;
}
#internas .espacos li:nth-child(odd) .content {
  float: right;
}
#internas .espacos .atracoes {
  padding: 50px 0;
}
#internas .espacos .atracoes h3 {
  padding: 0 0 30px 0;
  border: 0;
}
#internas .espacos .atracoes .swiper-slide {
  position: relative;
}
#internas .espacos .atracoes .swiper-slide a {
  display: block;
  width: 100%;
  height: 180px;
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
}
#internas .espacos .atracoes .swiper-slide a .label {
  position: absolute;
  z-index: 1;
  top: 0;
  height: 0;
  width: 100%;
  height: 100%;
  background: rgba(237, 23, 133, 0.5);
  text-align: center;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  font-family: 'BebasNeue', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  padding: 0 10px;
}
#internas .espacos .atracoes .swiper-slide a:hover .label {
  opacity: 1;
}
#internas .espacos .atracoes .navigation {
  padding-top: 15px;
  text-align: center;
}
#internas .espacos .atracoes .navigation span {
  width: 12px;
  height: 12px;
  margin: 0 2px;
  opacity: 1;
  background: rgba(255, 255, 255, 0.2);
}
#internas .espacos .atracoes .navigation span.swiper-pagination-bullet-active {
  background: #ed1785;
}
#internas #formularios {
  overflow: hidden;
  /*background:rgba(0,0,0,0.1);*/
  clear: both;
}
#internas #formularios p {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 24px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding-bottom: 25px;
}
#internas #formularios form:not(.vfbp-form) .input {
  overflow: hidden;
  padding-bottom: 20px;
}
#internas #formularios form:not(.vfbp-form) .input.full {
  width: 100%;
  clear: both;
}
#internas #formularios form:not(.vfbp-form) .input.half {
  width: calc(50% - 10px);
}
#internas #formularios form:not(.vfbp-form) label {
  display: none;
}
#internas #formularios form:not(.vfbp-form) input[type="text"],
#internas #formularios form:not(.vfbp-form) input[type="email"] {
  width: 100%;
  height: 50px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding: 0 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin: 10px 0;
}
#internas #formularios form:not(.vfbp-form) .select {
  width: 100%;
  background: rgba(255, 255, 255, 0.1) url("../images/arrow-seclts-form.png") no-repeat right 15px center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px 0 0 5px;
  margin: 10px 0;
}
#internas #formularios form:not(.vfbp-form) .select select {
  width: calc(100% + 20px);
  height: 50px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
  color: rgba(0, 0, 0, 0.8);
  font-style: normal;
  text-transform: none;
  padding: 0 15px;
  background: transparent;
}
#internas #formularios form:not(.vfbp-form) textarea {
  width: 100%;
  height: 200px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding: 10px 15px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 10px 0;
}
#internas #formularios form:not(.vfbp-form) .captcha {
  text-align: right;
}
#internas #formularios form:not(.vfbp-form) .captcha label {
  font-size: 14px;
  line-height: 40px;
  display: inline-block;
}
#internas #formularios form:not(.vfbp-form) .captcha label strong {
  color: #fff;
  font-size: 17px;
}
#internas #formularios form:not(.vfbp-form) .captcha input:not(.submit) {
  width: 50px;
  text-align: center;
  margin: 0 10px;
  font-style: normal;
  font-size: 18px;
}
#internas #formularios form:not(.vfbp-form) .captcha img {
  width: 50px;
  height: 50px;
  margin: 0 30px;
  display: inline-block;
  vertical-align: middle;
  display: none;
}
#internas #formularios form:not(.vfbp-form) input.submit {
  width: 120px;
  display: inline-block;
  background: #ed1785;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 50px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  border-radius: 5px;
}
#internas #formularios form:not(.vfbp-form) input.submit.full {
  width: 50%;
  left: 0;
  margin-left: 25%;
}
@media (max-width: 540px) {
  #internas #formularios form:not(.vfbp-form) input.submit.full {
    width: 100%;
    margin: 0;
  }
}
#internas #formularios form:not(.vfbp-form) input.submit:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #ff4da9;
}
#internas #formularios form:not(.vfbp-form) .input.fone .ddd {
  width: 50px;
  padding: 0;
  text-align: center;
  float: left;
  margin-right: 5px;
}
#internas #formularios form:not(.vfbp-form) .input.fone .numero {
  width: calc(100% - 55px);
}
@media (max-width: 540px) {
  #internas #formularios form:not(.vfbp-form) .input.half {
    width: 100%;
    clear: both !important;
    float: none !important;
  }
  #internas #formularios form:not(.vfbp-form) .captcha input:not(.submit) {
    margin-right: 0;
  }
  #internas #formularios form:not(.vfbp-form) .captcha input.submit {
    width: 100%;
    display: block;
    clear: both;
    margin-top: 20px;
  }
  #internas #formularios form:not(.vfbp-form) .captcha img {
    margin: 0 auto;
    margin-top: 20px;
  }
}
#internas #formularios .vfbp-form {
  padding: 0;
  position: relative;
  clear: both;
  overflow: hidden;
}
#internas #formularios .vfbp-form input[type="text"],
#internas #formularios .vfbp-form input[type="email"] {
  width: 100%;
  height: 50px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding: 0 15px;
  background: rgba(255, 255, 255, 0.1);
  margin: 10px 0;
  border: 0;
  border-radius: 0;
}
#internas #formularios .vfbp-form label {
  color: #b8b8b8;
}
#internas #formularios .vfbp-form .vfb-form-group {
  padding-top: 10px;
  margin-bottom: 0;
}
#internas #formularios .vfbp-form .vfb-fieldType-text label,
#internas #formularios .vfbp-form .vfb-fieldType-email label,
#internas #formularios .vfbp-form .vfb-fieldType-date label,
#internas #formularios .vfbp-form .vfb-fieldType-select label,
#internas #formularios .vfbp-form .vfb-fieldType-textarea label {
  display: none;
}
#internas #formularios .vfbp-form .vfb-fieldType-captcha label {
  display: none;
}
#internas #formularios .vfbp-form .vfb-form-control {
  height: 50px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 50px;
  color: #a7a7a7;
  font-style: normal;
  text-transform: uppercase;
  background: #f2f2f2;
  border: 1px solid #d6d6d6;
  box-shadow: none;
}
#internas #formularios .vfbp-form .vfb-fieldType-select {
  padding-top: 20px;
}
#internas #formularios .vfbp-form .vfb-fieldType-select label {
  margin-bottom: 10px;
}
#internas #formularios .vfbp-form .vfb-fieldType-select .vfb-form-group {
  padding-top: 0;
  background: rgba(255, 255, 255, 0.1);
}
#internas #formularios .vfbp-form .vfb-fieldType-select .vfb-form-group select {
  background: none;
  border: 0;
  color: #666;
  text-transform: none;
}
#internas #formularios .vfbp-form .vfb-fieldType-checkbox,
#internas #formularios .vfbp-form vfb-fieldType-radio {
  padding-top: 14px;
}
#internas #formularios .vfbp-form textarea.vfb-form-control {
  width: 100%;
  height: 150px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #000;
  font-style: normal;
  text-transform: none;
  padding: 10px 15px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 10px 0;
}
#internas #formularios .vfbp-form .vfb-fieldType-captcha {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 20px;
  margin-top: 20px;
}
#internas #formularios .vfbp-form .vfb-fieldType-submit {
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 20px;
}
#internas #formularios .vfbp-form .vfb-fieldType-submit button {
  height: 50px;
  padding: 0 20px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 50px;
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  width: 100%;
  background: #ed1785;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  border: 0;
  position: relative;
  top: 22px;
}
#internas #formularios .vfbp-form .vfb-fieldType-submit button:hover {
  background: #ed1785;
}
#internas aside {
  padding-left: 45px;
  overflow: hidden;
  position: relative;
}
#internas aside:before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 100%;
  border-left: 1px dashed rgba(255, 255, 255, 0.1);
  left: 20px;
}
#internas aside .widgets {
  overflow: hidden;
  padding-left: 15px;
}
#internas aside .widgets h2 {
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding-bottom: 30px;
}
#internas aside .widgets h2 strong {
  font-family: 'BebasNeue', sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
  color: #ed1785;
  font-style: normal;
  text-transform: none;
  display: block;
}
#internas aside .widgets.eventos {
  padding-bottom: 40px;
  clear: both;
}
#internas aside .widgets.eventos h2 {
  padding-bottom: 10px;
}
#internas aside .widgets.eventos li {
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  clear: both;
  overflow: hidden;
}
#internas aside .widgets.eventos img {
  float: left;
  width: 128px;
  margin-right: 15px;
}
#internas aside .widgets.eventos p {
  width: calc(100% - 143px);
  float: left;
}
#internas aside .widgets.eventos p.full {
  width: 100%;
  float: none;
}
#internas aside .widgets.eventos p strong {
  font-family: 'BebasNeue', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  display: block;
  padding-bottom: 5px;
}
#internas aside .widgets.eventos p time {
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  color: #a7a7a7;
  font-style: normal;
  text-transform: none;
}
#internas aside .widgets.servicos {
  padding-bottom: 40px;
  clear: both;
}
#internas aside .widgets.servicos.page {
  padding-bottom: 0;
}
#internas aside .widgets.servicos li {
  margin-bottom: 2px;
}
#internas aside .widgets.servicos li a {
  background-color: rgba(13, 13, 63, 0.6);
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #cfcfcf;
  font-style: italic;
  text-transform: none;
  position: relative;
  height: 40px;
  padding: 0 10px 0 0;
}
#internas aside .widgets.servicos li a i {
  display: block;
  float: left;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  vertical-align: middle;
  color: #ed1785;
  font-size: 22px;
}
#internas aside .widgets.servicos li a:hover {
  background: rgba(10, 10, 32, 0.6);
  color: #fff;
}
#internas aside .widgets.servicos li a:hover:before {
  content: "";
  position: absolute;
  right: 10px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\f054";
  color: #fff;
  font-size: 14px;
  top: 50%;
  transform: translateY(-50%);
}
#internas aside .widgets.categorias {
  padding-bottom: 40px;
  clear: both;
}
#internas aside .widgets.categorias li {
  margin-bottom: 2px;
}
#internas aside .widgets.categorias li a {
  background-color: rgba(10, 10, 32, 0.6);
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 50px;
  color: #cfcfcf;
  font-style: italic;
  text-transform: none;
  position: relative;
  height: 50px;
  padding-left: 10px;
}
#internas aside .widgets.categorias li a i {
  display: block;
  float: left;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  vertical-align: middle;
  color: #ed1785;
  font-size: 24px;
}
#internas aside .widgets.categorias li a:hover {
  background: #0a0a20;
  color: #fff;
}
#internas aside .widgets.categorias li a:hover:before {
  content: "";
  position: absolute;
  right: 10px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\f054";
  color: #fff;
  font-size: 14px;
  top: 50%;
  transform: translateY(-50%);
}
.orcamento {
  background: rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  clear: both;
  margin-top: 80px;
  margin-bottom: 80px;
}
.orcamento h3 {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 22px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: center;
}
.noPad {
  padding: 0 !important;
}
.noMarginTop {
  margin-top: 0 !important;
}
.mTop12 {
  margin-top: 12px;
}
.noBorder {
  border: 0;
}
.alignMiddle {
  vertical-align: middle;
}
/* --------------------------------------------------------------
FOOTER
----------------------------------------------------------------- */
/* COMPLEMENTOS
------------------------------------- */
#complemento {
  position: relative;
  overflow: hidden;
  padding: 60px 0;
}
#complemento .container,
#complemento .row {
  height: 100%;
  position: relative;
}
#complemento .blog .slide {
  position: relative;
}
#complemento .blog .swiper-container {
  height: 340px;
  width: 100%;
  z-index: 0;
}
#complemento .blog .swiper-slide {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(25, 25, 25, 0.9);
  background-attachment: inherit;
}
#complemento .blog .swiper-slide a {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 10px;
  border-left: 8px solid #ed1785;
  padding: 2px 0 40px 25px;
  margin-left: 10px;
}
#complemento .blog .swiper-slide h3 {
  font-family: 'BebasNeue', sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 36px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 5px;
  display: inline-block;
  max-width: calc(100% - 160px);
}
#complemento .blog .navigation {
  position: absolute;
  z-index: 1;
  bottom: 7px;
  left: 0;
  padding: 0 15px;
  width: 100%;
}
#complemento .blog .navigation .balls {
  padding-left: 28px;
  height: 40px;
  line-height: 40px;
  float: left;
}
#complemento .blog .navigation .balls span {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
  margin: 0 2px;
  opacity: 1;
}
#complemento .blog .navigation .balls span.swiper-pagination-bullet-active {
  background: #fff;
}
#complemento .blog .navigation .arrows {
  float: right;
  padding-right: 0;
}
#complemento .blog .navigation .arrows span {
  display: inline-block;
  width: 40px;
  text-align: center;
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  -webkit-transition: all 400ms ease;
  /* Safari */
  transition: all 400ms ease;
  margin: 0 5px;
}
#complemento .blog .navigation .arrows span i {
  font-size: 30px;
  height: 40px;
  line-height: 40px;
}
#complemento .blog .navigation .arrows span:hover {
  color: #fff;
}
#complemento .blog .footer {
  padding-top: 40px;
}
#complemento .depoimentos {
  position: relative;
}
#complemento .depoimentos .fa-quote-left {
  height: 55px;
  color: #fff;
  font-size: 40px;
}
#complemento .depoimentos .swiper-container {
  height: 260px;
  width: 100%;
  z-index: 0;
}
#complemento .depoimentos .swiper-slide {
  height: 100%;
}
#complemento .depoimentos cite {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  color: #fff;
  font-style: italic;
  text-transform: none;
}
#complemento .depoimentos cite span {
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: #7d7d7d;
  font-style: normal;
  text-transform: none;
  padding-top: 15px;
}
#complemento .depoimentos cite span i {
  vertical-align: middle;
  font-size: 12px;
  margin-right: 5px;
  position: relative;
  top: -4px;
}
#complemento .depoimentos .footer {
  padding-top: 65px;
  position: relative;
}
#complemento .depoimentos .navigation {
  position: absolute;
  top: 8px;
}
#complemento .depoimentos .navigation span {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
  margin: 0 2px;
  opacity: 1;
}
#complemento .depoimentos .navigation span.swiper-pagination-bullet-active {
  background: #fff;
}
#complemento .depoimentos:after {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 423px;
  background: rgba(255, 255, 255, 0.1);
  left: -48px;
  top: 76px;
}
@media (max-width: 992px) {
  #complemento {
    padding: 40px 0 0 0;
  }
  #complemento .depoimentos .swiper-container {
    height: inherit;
    margin-bottom: 20px;
  }
  #complemento .depoimentos cite {
    top: inherit;
    transform: none;
  }
  #complemento .depoimentos {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    background: rgba(0, 0, 0, 0.4);
  }
}
@media (max-width: 540px) {
  #complemento .blog .swiper-container {
    height: 280px;
  }
  #complemento .blog .swiper-slide a {
    padding: 2px 0 40px 15px;
    margin-left: 10px;
  }
  #complemento .blog .swiper-slide h3 {
    font-size: 24px;
    line-height: 28px;
    max-width: calc(100% - 40px);
  }
  #complemento .depoimentos cite {
    font-size: 16px;
    line-height: 20px;
  }
  #complemento .depoimentos cite span {
    font-size: 14px;
    line-height: 14px;
  }
}
/* RODAPÉ
------------------------------------- */
body > footer .informacoes {
  padding: 40px 0;
  background: rgba(10, 10, 32, 0.8);
}
body > footer .informacoes address {
  float: left;
}
body > footer .informacoes address span {
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 26px;
  color: #fff;
  font-style: normal;
  text-transform: none;
  padding-bottom: 10px;
}
body > footer .informacoes address a.mapa {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #ed1785;
  font-style: normal;
  text-transform: none;
  display: inline-block;
}
body > footer .informacoes address a.mapa i {
  margin-right: 5px;
  color: #ed1785;
  font-size: 16px;
}
body > footer .informacoes ul {
  float: right;
  text-align: right;
}
body > footer .informacoes ul li {
  padding: 0;
}
body > footer .informacoes ul li a {
  font-family: 'Lato', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
  color: #fff;
  font-style: normal;
  text-transform: none;
}
body > footer .informacoes ul li a i {
  font-size: 18px;
  color: #ed1785;
  margin-right: 5px;
}
body > footer .rodape {
  height: 50px;
  background: #0d0d3f;
}
body > footer .rodape .copy {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 50px;
  color: #777777;
  font-style: normal;
  text-transform: none;
}
body > footer .rodape .dev {
  text-align: right;
}
body > footer .rodape .dev ul {
  float: right;
}
body > footer .rodape .dev ul li {
  display: inline-block;
  padding: 0 20px;
  line-height: 50px;
}
body > footer .rodape .dev ul li a {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #d8d8d8;
  font-style: normal;
  text-transform: none;
  display: inline-block;
  height: 18px;
  position: relative;
}
body > footer .rodape .dev ul li a:after {
  content: "";
  width: 1px;
  height: 18px;
  background: rgba(255, 255, 255, 0.15);
  right: -22px;
  top: 0;
  position: absolute;
}
body > footer .rodape .dev ul li a:hover {
  text-decoration: underline;
}
body > footer .rodape .dev a.prog {
  display: block;
  float: right;
  margin-left: 30px;
}
@media (max-width: 992px) {
  body > footer .informacoes address {
    float: none;
    text-align: center;
    margin-bottom: 30px;
  }
  body > footer .informacoes address span {
    font-size: 18px;
  }
  body > footer .informacoes ul {
    float: none;
    text-align: center;
  }
  body > footer .rodape {
    height: inherit;
    padding-top: 10px;
  }
  body > footer .rodape .copy {
    width: 100%;
    float: none;
    padding: 10px 40px;
    line-height: 16px;
    text-align: center;
  }
  body > footer .rodape .dev {
    text-align: center;
    width: 100%;
    padding: 0;
    float: none;
  }
  body > footer .rodape .dev ul {
    display: block;
    float: none;
    width: 100%;
  }
  body > footer .rodape .dev ul li:last-child a:after {
    content: none;
  }
  body > footer .rodape .dev a.prog {
    display: inline-block;
    float: none;
    clear: both;
    margin-left: 0;
  }
}
.fancybox-caption {
  text-align: center;
}
/* -----------------------------------------
AJUSTES DE RESPONSIVO
----------------------------------------- */
@media only screen and (max-width: 1090px) {
  .listPostEventos .content .addthis_inline_share_toolbox {
    float: left;
    display: contents;
  }
  .listPostEventos .content .btn.rosa.fill {
    margin-bottom: 10px;
  }
  #bannerHome .navigation {
    right: 60%;
  }
  #internas .page-blog .postList .postItem .col {
    margin-right: 10px;
  }
  #internas .page-blog .postList .postItem .piContent {
    display: contents;
  }
  #internas .page-blog .postList .postItem .piContent h2 a {
    padding-top: 10px;
  }
  #internas article.single-eventos {
    padding: 40px 0 20px 0;
  }
  #internas article.single-eventos .intro p {
    font-size: 18px;
    line-height: 22px;
  }
  #internas aside {
    padding-left: 0;
  }
  #internas aside:before {
    left: 0;
  }
  #internas .page-blog {
    padding: 40px 0 20px 0;
  }
  body > footer .rodape .copy {
    font-size: 12px;
  }
  #internas .page-localizacao {
    padding: 40px 0;
  }
  #internas .page-single-servicos {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .orcamento {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 992px) {
  #internas article.single-eventos .intro {
    display: contents;
  }
  #internas article.single-eventos .post {
    padding-top: 40px;
  }
  #internas .espacos li .gallery {
    width: 100%;
  }
  #internas .espacos li .gallery .swiper-container .swiper-slide:style {
    width: 100%;
  }
  #internas aside {
    padding-top: 40px;
    border-top: 1px dashed rgba(255, 255, 255, 0.2);
    margin-top: 20px;
  }
  #internas aside:before {
    content: none;
  }
  #internas .espacos li {
    padding-bottom: 20px;
  }
  #internas .espacos li .content {
    height: inherit;
  }
  #internas .espacos li .content .middle {
    top: inherit;
    transform: none;
    padding-top: 30px;
  }
  #internas .espacos li:nth-child(2) {
    text-align: left;
  }
  #internas .espacos .atracoes {
    padding: 30px 0;
  }
  #internas .formContato {
    padding: 40px 0 0 0;
  }
}
@media only screen and (max-width: 767px) {
  #internas #formularios .vfbp-form .vfb-fieldType-submit button {
    top: 0;
  }
  #internas .page-blog .postList .postItem {
    height: 270px;
  }
}
@media only screen and (max-width: 540px) {
  .listPostEventos .content .addthis_inline_share_toolbox {
    display: inherit;
  }
  .listPostEventos .content .addthis_inline_share_toolbox:not(.category) {
    padding-top: 0;
  }
  #bannerHome .navigation {
    right: 40%;
  }
  #internas article.single-eventos .intro {
    overflow: visible;
  }
  #internas article.single-eventos .intro figure {
    width: 100%;
    height: 200px;
    float: none;
  }
  #internas article.single-eventos .intro .content {
    width: 100%;
    height: inherit;
    float: none;
    clear: both;
  }
  #internas article.single-eventos .intro .content .middle {
    top: inherit;
    transform: none;
  }
  #internas aside {
    padding-left: 0;
  }
  #internas aside:before {
    display: none;
  }
  #internas .page-blog .postList .postItem .piContent .socialBar {
    margin: 0 10px;
  }
  #internas .page-blog .postList .postItem {
    height: inherit;
    clear: both;
    overflow: hidden;
  }
  #internas .page-blog .postList .postItem .col {
    width: 100%;
    float: none;
    height: auto;
    display: block;
  }
  #internas .page-blog .postList .postItem .piContent {
    width: 100%;
    float: none;
    clear: both;
    height: auto;
    overflow: hidden;
    padding: 10px 15px 20px 15px;
    display: block;
  }
  #internas .page-blog .postList .postItem .piContent.full {
    padding: 0 5px;
  }
  #internas .page-blog .postList .postItem .piContent a.btn {
    padding: 0 10px;
  }
  #internas .page-blog .postList .postItem .piContent .socialBar {
    float: right;
    margin: 0;
  }
  #internas .page-blog .postList .postItem.full .piContent.full .socialBar {
    margin: 0;
    float: right;
  }
  #internas .page-blog-single article {
    padding: 20px 10px;
  }
  #internas .page-blog-single article h1 {
    font-size: 26px;
    line-height: 26px;
    padding: 8px 10px 8px 30px !important;
  }
  #internas .page-blog-single article .facebookComment .faceBox {
    padding: 10px 5px;
  }
  #internas .page-localizacao h3 {
    font-size: 27px;
    line-height: 29px;
  }
  #internas .espacos li .gallery .swiper-container {
    height: 230px;
  }
}
@media only screen and (max-width: 482px) {
  #internas .espacos ul li .content .middle {
    top: 65%;
  }
}
@media only screen and (max-width: 438px) {
  #internas article.single-eventos .intro .middle {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 397px) {
  #internas article.single-eventos .intro .middle {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 348px) {
  #internas article.single-eventos .intro .middle {
    margin-top: 40px;
  }
  #internas article.single-eventos .post {
    padding-top: 90px;
  }
  #internas .page-blog .postList .postItem {
    height: 410px;
  }
}
